<template>
  <MaireDialog
    :onClose="() => (deletionModalOpen = false)"
    v-if="analysisToDelete"
    :open="deletionModalOpen"
    :title="`Are you sure you want to delete analysis: ${analysisToDelete.name}?`"
    confirmText="Delete"
    :fn="() => analysisToDelete?.id && deleteAnalysis(analysisToDelete)"
  />
  <MaireDialog
    :onClose="() => (deletionModalOpen = false)"
    v-if="viewToDelete"
    :open="deletionModalOpen"
    :title="`Are you sure you want to delete view ${viewToDelete.name}?`"
    confirmText="Delete"
    :fn="() => viewToDelete?.id && deleteView(viewToDelete)"
  />
  <MaireDialog
    v-if="analysisModalOpen"
    :open="analysisModalOpen"
    :fn="handleAnalysisSave"
    title="Create new analysis"
    :onClose="() => (analysisModalOpen = false)"
  />
  <CreateAnalysisFilterModal
    v-if="analysisModalOpen"
    :open="analysisModalOpen"
    :onSave="handleAnalysisSave"
    @close="analysisModalOpen = false"
  />
  <div class="my-analyses maire-scrollbar horizontal-padding">
    <div class="centered-info" v-if="analysisFilters.length === 0">
      <img :src="`${publicPath}images/glass.gif`" alt="Magnifying glass" />
      <p v-if="!userCanCreateAnalysis" class="xs strong">No analyses shared with you yet.</p>
      <p v-else class="xs strong">No analyses found. Create a new analysis by clicking the button below! </p>
      <div v-if="userCanCreateAnalysis" style="margin-top: 24px">
        <MaireButton
          @click="analysisModalOpen = true"
          text="Create new analysis"
          inverse
          append-icon="fas fa-plus"
          compact
        />
      </div>
    </div>

    <div v-else>
      <div class="container">
        <div class="buttons_container">
          <div class="item">
            <MaireButton
              @click="analysisModalOpen = true"
              text="Create new analysis"
              inverse
              append-icon="fas fa-plus"
              compact
            />
          </div>
          <div class="item">
            <MaireButton
              @click="showOnlyOwnAnalyses = !showOnlyOwnAnalyses"
              :text="showOnlyOwnAnalyses ? 'Show all analyses' : 'Show only my own analyses'"
              compact
            />
          </div>
        </div>
        <div class="search_container">
          <SearchBar @set="searchWordChanged" hide-details />
        </div>
      </div>
      <div class="table-header-row">
        <div class="table-header">
          <span></span>
          <div class="table-header-cell xs">Modified</div>
          <div class="table-header-cell xs">Name & Description</div>
          <div class="table-header-cell xs">Owner</div>
        </div>
      </div>
      <div class="table-content">
        <div :key="analysis.id" v-for="analysis in sortedAnalysisFilters">
          <div class="table-row" @click="showViewsForAnalysis(analysis)">
            <div class="cell" v-if="userCanDeleteAnalysis(analysis)">
              <ThreeDotMenu
                :actions="getActionsForAnalysis(analysis)"
                :isOpen="analysisMenuOpen === analysis.id"
                @menu-opened="analysisMenuOpen = analysis.id"
                @menu-closed="analysisMenuOpen = undefined"
              />
            </div>
            <div v-else></div>
            <p class="cell xs maire-grey-0">{{ latestDateForAnalysis(analysis) }}</p>
            <div class="cell">
              <div>
                <p class="s">{{ analysis.name }}</p>
                <p class="xs maire-grey-0">{{ analysis.description }}</p>
              </div>
            </div>
            <div class="cell">
              <div>
                <p class="xs">{{ userMap?.[analysis.user_id]?.name }}</p>
                <p class="xs maire-grey-0">{{ userMap?.[analysis.user_id]?.email }}</p>
              </div>
            </div>
            <div>
              <MaireIcon
                icon="fas fa-users"
                :tooltip="`Shared by: ${analysisSharedBy(analysis)}`"
                v-if="analysisSharedBy(analysis)"
              />
              <MaireIcon
                icon="fab fa-google"
                tooltip="Includes search volumes from Google only"
                v-if="analysis?.volume_field === 'monthly_google_searches'"
              />
            </div>
            <span class="caret">
              <v-icon v-if="analysis.views?.length > 1">{{
                expanded.includes(analysis.id) ? "far fa-caret-up" : "far fa-caret-down"
              }}</v-icon>
            </span>
          </div>

          <Transition name="slide">
            <div class="views" v-if="expanded.includes(analysis.id)">
              <div
                class="table-row view-row"
                :key="view.id"
                v-for="view in analysis.views"
                @click="selectView(analysis, view)"
              >
                <div class="cell" v-if="userCanDeleteView(view)">
                  <ThreeDotMenu
                    :actions="[{ name: 'Delete', icon: 'far fa-trash', fn: () => triggerViewDeletion(view) }]"
                    :isOpen="viewMenuOpen === view.id"
                    @menu-opened="viewMenuOpen = view.id"
                    @menu-closed="viewMenuOpen = undefined"
                  />
                </div>
                <div v-else></div>
                <p class="cell xs maire-grey-0">{{ getFormattedDateForView(view) }}</p>
                <div class="cell">
                  <div>
                    <p class="s">{{ view.name }}</p>
                    <p class="xs maire-grey-0">{{ view.description }}</p>
                  </div>
                </div>
                <div class="cell">
                  <div>
                    <p class="s">{{ userMap?.[view.user_id]?.name }}</p>
                    <p class="xs maire-grey-0">{{ userMap?.[view.user_id]?.email }}</p>
                  </div>
                </div>
                <div>
                  <MaireIcon
                    icon="fas fa-users"
                    :tooltip="`Shared by: ${viewSharedBy(view)}`"
                    v-if="viewSharedBy(view)"
                  />
                  <MaireIcon
                    icon="fab fa-google"
                    tooltip="Includes search volumes from Google only"
                    v-if="analysis?.volume_field === 'monthly_google_searches'"
                  />
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  gap: 10px;
}
.centered-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50%;
}
.buttons_container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.search_container {
  align-self: flex-end;
  align-self: center;
  width: 300px;
}
.caret {
  color: rgb(var(--v-theme-mairePurpleSecondary));
  display: flex;
  align-items: center;
  justify-content: center;
}
.cell {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.slide-leave-active,
.slide-enter-active {
  transition: all 0.1s ease-in-out;
}

.slide-enter-to,
.slide-leave-from {
  max-height: 500px;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 24px;
  opacity: 0;
}
.action {
  margin-right: 5px;
  margin-left: 12px !important;
  right: 0px;
  text-align: right;
  display: flex;
  align-items: center;
  white-space: nowrap; /* Prevent line wrapping */
}
.my-analyses {
  position: relative;
  height: 100vh;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 70px;
  border-radius: 0px !important;
}

.table-content {
  max-height: 100%;
  left: 0px;
  right: 0px;
  margin: auto;
}

.table-row {
  display: flex;
  width: 100%;
  background-color: white;
  margin: 12px 0px;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 0.3fr 1fr 5fr 1fr 1.5fr 0.2fr;
  border-radius: 8px;
}

.table-row:hover {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.2);
}
.table-header {
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 1fr 5fr 1fr 1.5fr 0.2fr;
  white-space: nowrap;
  max-width: 100%;
  max-height: 100%;
  padding: 0px 12px;
}

.table-header-cell {
  width: 25%;
  padding: 12px 0px 0px;
}
.views {
  margin: 12px 0px 12px 0px;
}
.view-row {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.1);
}
</style>
<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from "vue";
import { Analysis, View } from "@/store/modules/analysis/types";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import MaireIcon from "@/components/MaireIcon/MaireIcon.vue";
import ThreeDotMenu from "@/components/ThreeDotMenu/ThreeDotMenu.vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import SearchBar from "@/components/DataTable/SearchBar.vue";
import { keywordMatchesSearchWord } from "@/components/helpers/helpers";
import CreateAnalysisFilterModal from "@/components/CreateAnalysisFilterModal/CreateAnalysisFilterModal.vue";
const analysisModalOpen = ref(false);
const showOnlyOwnAnalyses = ref(false);
const analysisMenuOpen: Ref<number | undefined> = ref(undefined);
const viewMenuOpen: Ref<number | undefined> = ref(undefined);
const searchWord = ref("");
const store = useStore();
const router = useRouter();
const publicPath = process.env.BASE_URL;
onMounted(() => {
  if (!analysisFilters.value?.length) {
    store.dispatch("analysis/fetchAnalyses");
  }
});

// TABLE INFORMATION HELPERS
const getActionsForAnalysis = (analysis: Analysis) => {
  const actions = [
    {
      name: "Delete",
      icon: "far fa-trash",
      fn: () => triggerAnalysisDeletion(analysis),
      inProgress: false,
    },
  ];
  if (["admin", "company_admin"].includes(user.value.type)) {
    actions.push({
      name: "Download volumes",
      icon: "far fa-download",
      fn: () => triggerVolumeDownload(analysis),
      inProgress: loadingVolumes.value.includes(analysis.id),
    });
  }
  return actions;
};
const formatDate = (dt: Date) => {
  const now = new Date();

  // If today, show time (12:15)
  if (dt.toDateString() === now.toDateString()) {
    return dt.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  }

  // If this year, show date (13 Jun)
  if (dt.getFullYear() === now.getFullYear()) {
    return dt.toLocaleDateString([], { day: "numeric", month: "short" });
  }

  // If previous years, show month year (Jul 2022)
  return dt.toLocaleDateString([], { year: "numeric", month: "short" });
};
const getFormattedDateForView = (view: View) => {
  const date = new Date(view.time_updated || view.time_created);
  return formatDate(date);
};
const latestDateForAnalysis = (analysis: Analysis) => {
  // Get all timestamps from the views
  const dates = analysis.views.map((view) => new Date(view.time_updated || view.time_created).getTime());

  // Find the latest date
  const maxDate = new Date(Math.max(...dates));
  return formatDate(maxDate);
};

// VIEW & ANALYSIS DELETION LOGIC
const viewToDelete: Ref<View | undefined> = ref(undefined);
const analysisToDelete: Ref<Analysis | undefined> = ref(undefined);
const deletionModalOpen = ref(false);

const triggerAnalysisDeletion = (analysis: Analysis) => {
  viewToDelete.value = undefined;
  analysisToDelete.value = analysis;
  deletionModalOpen.value = true;
};

const triggerViewDeletion = (view: View) => {
  analysisToDelete.value = undefined;
  viewToDelete.value = view;
  deletionModalOpen.value = true;
};
const deleteView = (view: View) => {
  deletionModalOpen.value = false;
  store.dispatch("analysis/deleteView", view);
};

const deleteAnalysis = (analysis: Analysis) => {
  deletionModalOpen.value = false;
  store.dispatch("analysis/deleteAnalysis", analysis);
};

const triggerVolumeDownload = (analysis: Analysis) => {
  store.dispatch("analysis/downloadVolumes", analysis);
};

const userCanDeleteAnalysis = (analysis: Analysis) => {
  return user.value?.type === "company_admin" || analysis.user_id === user.value?.id;
};
const userCanDeleteView = (view: View) => {
  return user.value?.type === "company_admin" || view.user_id === user.value?.id;
};
const userCanCreateAnalysis = computed(() => user.value.type !== "viewer");

// ANALYSIS CREATION
const handleAnalysisSave = (analysisToCreate: Partial<Analysis>) => {
  store.dispatch("analysis/createOrUpdateAnalysis", analysisToCreate);
};

// ANALYSIS TOGGLING LOGIC
const expanded: Ref<number[]> = ref([]);
const showViewsForAnalysis = (analysis: Analysis) => {
  if (analysis.views.length === 1) {
    selectView(analysis, analysis.views[0]);
    return;
  }
  if (expanded.value.includes(analysis.id)) {
    expanded.value = expanded.value.filter((id) => id !== analysis.id);
  } else {
    expanded.value.push(analysis.id);
  }
};

const selectView = (analysis: Analysis, view: View) => {
  if (!view) return;
  if (!analysis) return;
  router.push(`/analysis/${analysis.id}?view=${view.id}`);
};

const analysisSharedBy = (analysis: Analysis) => {
  if (!user.value || analysis.user_id === user.value?.id) {
    return null;
  }
  const sharedUser = company.value?.active_users?.find((user) => user.id === analysis.user_id);
  if (!sharedUser) {
    return null;
  }
  return `${sharedUser.first_name} ${sharedUser.last_name}`;
};

const user = computed(() => {
  return store.state.user;
});

const viewSharedBy = (view: View) => {
  if (!user.value || view.user_id === user.value?.id) {
    return null;
  }
  const sharedUser = company.value?.active_users?.find((user) => user.id === view.user_id);
  if (!sharedUser) {
    return null;
  }
  return `${sharedUser.first_name} ${sharedUser.last_name}`;
};

const company = computed(() => {
  return store.state.company;
});

const users = computed(() => {
  return store.state.company.active_users;
});
const userMap = computed(() => {
  if (!users.value) return {};
  return Object.fromEntries(
    users.value?.map((user) => [
      user.id,
      { name: `${user.first_name} ${user.last_name}`, email: user.email },
    ]) ?? []
  );
});

const views = computed(() => {
  return store.state.analysis.views;
});

const searchWordChanged = (val: string) => {
  searchWord.value = val;
};
const analysisFilters = computed(() => {
  const afs = store.state.analysis.analyses.map((analysis: Analysis) => {
    return {
      ...analysis,
      views: analysis.views.map((view) => views.value[view.id]),
    };
  });
  return (showOnlyOwnAnalyses.value ? afs.filter((af) => af.user_id === user.value.id) : afs).filter((af) =>
    keywordMatchesSearchWord(
      // @ts-ignore
      { keyword: af.name },
      searchWord.value,
      JSON.stringify({ ...af, id: undefined, user: userMap.value?.[af.user_id] })
    )
  );
});
const sortedAnalysisFilters = computed(() => {
  return [...analysisFilters.value].sort((a: Analysis, b: Analysis) => {
    return a.name.localeCompare(b.name);
  });
});
const loadingVolumes = computed(() => {
  return store.state.loading.downloadingAnalysisVolumes;
});
</script>
