<template>
  <div :class="`card`">
    <div class="card-content inputs nopadding light">
      <div class="card-title">{{ title }}</div>
      <slot></slot>
      <div v-if="!hideInput" :class="`input ${transparent ? 'transparent' : ''}`">
        <v-text-field
          v-if="!itemList?.length"
          :prepend-inner-icon="icon"
          density="compact"
          persistent-placeholder
          @keydown="handleKeydown"
          class="nopadding"
          @paste="handlePaste"
          v-model="textValue"
          hide-details
          :label="label"
          :placeholder="placeholder ?? 'Add words here and press enter'"
          variant="outlined"
          clearable
          outlined
        >
        </v-text-field>
        <v-autocomplete
          v-if="itemList?.length"
          :items="itemList"
          item-title="text"
          item-value="id"
          :prepend-inner-icon="icon"
          density="compact"
          persistent-placeholder
          :placeholder="placeholder"
          v-model="itemValue"
          :hideDetails="true"
          @update:modelValue="saveLabel"
          @update:focused="handleFocused"
          :label="label"
          variant="outlined"
          outlined
          :clearable="clearable && closable"
          :multiple="multiple"
          :hideSelected="hideSelected"
          :list-props="{ maxWidth: '350px', width: '500px' }"
        >
          <template #selection></template>
        </v-autocomplete>
      </div>
      <slot name="chips" v-if="!hideChips">
        <MaireChips
          :closable="closable"
          :items="items"
          :density="density"
          :field="field"
          :limit="limit"
          :color="color"
          @click="handleLabelChipClick"
          @close="handleClose"
        />
      </slot>
    </div>
  </div>
</template>
<style scoped>
.rule-chips {
  margin-top: 8px;
}
.card {
  position: relative;
  text-align: left;
  padding: 12px 0px;
  color: black;
  /* background-color: white; */
  border-radius: 10px;
  width: 100%;
}

.input {
  background-color: white;
  border-radius: 10px;
}

.transparent {
  background-color: inherit;
}

.card-title {
  color: #8200ff;
  font-size: 16px;
  font-weight: 800;
  font-family: "Nunito Sans", extrabold;
  text-align: left;
}
</style>
<script lang="ts" setup>
import { defineProps, defineEmits, ref, watch } from "vue";
import MaireChips from "./MaireChips.vue";
import { Label } from "@/store/modules/labels/types";

interface Item {
  [key: string]: string;
}
const emit = defineEmits(["click", "remove", "add", "addLabel", "removeLabel"]);
const props = defineProps({
  title: { type: String, required: false },
  icon: { type: String, required: false, default: "far fa-key-skeleton" },
  field: { type: String, required: false, default: "value" },
  limit: { type: Number, required: false },
  items: { type: Array as () => (Label | Item | string)[], required: true, default: () => [] },
  itemList: { type: Array as () => (Label | Item | string)[], required: true, default: () => [] },
  selected: { type: Array as () => number[], required: false },
  color: { type: String, required: false },
  requireConfirmation: { type: Boolean, required: false, default: () => false },
  hideInput: { type: Boolean, required: false, default: () => false },
  closable: { type: Boolean, required: false, default: () => true },
  clearable: { type: Boolean, required: false, default: () => true },
  transparent: { type: Boolean, required: false, default: () => false },
  hideChips: { type: Boolean, required: false, default: () => false },
  multiple: { type: Boolean, required: false, default: () => false },
  hideSelected: { type: Boolean, required: false, default: () => false },
  label: { type: String, required: false, default: "Descriptive words" },
  placeholder: { type: String, required: false },
  density: { type: String, required: false },
  linkFn: { type: Function, required: false },
});

const textValue = ref("");
const itemValue = ref();

watch(
  () => props.selected,
  () => {
    itemValue.value = props.selected;
  },
  { immediate: true }
);

const handlePaste = (event: ClipboardEvent) => {
  event.preventDefault();
  const text = event.clipboardData?.getData("text") as string;
  const words = text.split(/[\r\n,;]+/);
  words.forEach((word) => {
    emit("add", word.replace("\r", ""));
  });
};
const saveLabel = () => {
  if (props.multiple) return;
  if (itemValue.value) emit("add", itemValue.value);
  textValue.value = "";
  // itemValue.value = null;
};

const handleFocused = (focused: any) => {
  if (props.multiple && !focused) {
    emit("add", itemValue.value);
  }
};
const handleKeydown = (event: KeyboardEvent) => {
  if (textValue.value && event.code.includes("Enter")) {
    emit("add", textValue.value, event);
    textValue.value = "";
  }
};
const handleLabelChipClick = (item: any, e: any) => {
  if (!props.linkFn) return;
  const link = props.linkFn(item);
  if (link && (e.metaKey || e.ctrlKey)) {
    window.open(link, "_blank");
  }
};
const handleClose = (lbl: Label | string) => {
  emit("remove", lbl);
};
</script>
