<template>
  <div id="edit-user-dialog">
    <MaireDialog
      :title="user ? `Edit ${user.first_name} ${user.last_name}` : ''"
      description=""
      :open="!!user"
      :confirmDisabled="!currentUserType || currentUserType === newUserType"
      :fn="editUser"
      :onClose="close"
    >
      <div v-if="currentUserType && currentUserType !== 'admin'">
        <v-select
          :items="userTypes"
          item-title="name"
          item-value="id"
          ref="newUserType"
          v-model="newUserType"
          label="User type"
          density="compact"
        >
        </v-select>
      </div>
      <p v-else>Maire admins can't be edited here</p>
      <p v-if="errorText">{{ errorText }}</p>
    </MaireDialog>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import * as api from "@/api";
import { useStore } from "@/store";
import MaireDialog from "../MaireDialog/MaireDialog.vue";

export default defineComponent({
  name: "EditUserDialog",
  components: {
    MaireDialog,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  props: {
    user: { type: Object },
    resetUser: { type: Function, required: true },
    userTypes: { type: Object, required: true },
  },
  data() {
    return {
      newUserType: this.user?.type !== "admin" ? this.user?.type : undefined,
      errorText: "",
    };
  },
  computed: {
    currentUserType() {
      return this.user?.type !== "admin" ? this.user?.type : undefined;
    },
  },
  methods: {
    editUser() {
      this.errorText = "";
      api
        .editUser(this.user.id, { type: this.newUserType })
        .then(() => {
          this.store.dispatch("fetchAllCompanies");
          this.close();
        })
        .catch((e) => (this.errorText = `Failed to edit user: ${e}`));
    },
    close() {
      this.errorText = "";
      this.resetUser();
    },
  },
});
</script>
