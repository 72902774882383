<template>
  <div class="container checkboxcell" v-if="hasRules">
    <v-icon :color="color">{{ resolvedIcon }}</v-icon>
  </div>
</template>
<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<script lang="ts" setup>
import { defineProps, computed, PropType } from "vue";
import { LabelRule } from "@/store/modules/labels/types";
const props = defineProps({
  fieldValue: Array as PropType<Array<LabelRule>>,
  field: Object,
  item: Object,
  icon: { type: String, required: false },
  color: { type: String, required: false },
  getIcon: { type: Function, required: false },
});

const hasRules = !!props.fieldValue?.length;
const resolvedIcon = computed(() => props.icon ?? props.getIcon?.(props.fieldValue));
</script>
