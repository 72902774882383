import { AnalysisFilters } from "@/store/modules/analysis/types";
import { TimeRange } from "../MonthPicker/MonthPicker.vue";

export function yearMonthKeysInRange(range: TimeRange) {
  const keys = [];
  for (let year = range.start.year; year <= range.end.year; year++) {
    // months are also part of the range
    const startMonth = year === range.start.year ? range.start.month : 1;
    const endMonth = year === range.end.year ? range.end.month : 12;
    for (let month = startMonth; month <= endMonth; month++) {
      keys.push({ year, month });
    }
  }
  return keys;
}

export function postFixForFields(field: string) {
  const fieldsPostFix = {
    potential: "€",
    volume: "",
  };
  if ((field?.includes("growth") && !field.includes("Absolute")) || field === "trending") {
    return "%";
  }
  return fieldsPostFix[field as keyof typeof fieldsPostFix];
}

export const getEffectiveFilter = (filter: AnalysisFilters) => {
  if (!filter) return "";
  const filterCopy = JSON.parse(JSON.stringify(filter ?? {}));
  // checkbox filters should not affect unless they're selected
  filterCopy.countryFilter = filter.countryFilter.filter((cb) => cb.checked);
  filterCopy.languageFilter = filter.languageFilter.filter((cb) => cb.checked);
  filterCopy.trendingFilter = filter.trendingFilter.filter((cb) => cb.checked);
  filterCopy.labelFilters.include.labels = filter.labelFilters.include.labels.filter((cb) => cb.checked);
  filterCopy.volumeRange = { min: filter.volumeRange.min, max: filter.volumeRange.max };
  return filterCopy;
};
