import { generateExampleFilters } from "@/store";
import { AnalysisFilters, AnalysisSummary, CheckboxState, LabelFilter } from "@/store/modules/analysis/types";
import { Keyword } from "@/store/modules/keywords/types";
import { viewLabelsToStructure } from "@/store/modules/labels/helpers";
import { ViewLabel } from "@/store/modules/labels/types";

export const toUSD = (value: number) =>
  new Intl.NumberFormat("fi-FI", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  }).format(value);

export const numberFormatter = (num: number, digits: number, includePlusSymbol: boolean = false) => {
  let isNegative = false;
  if (num && num < 0) {
    isNegative = true;
    // return num.toFixed(digits);
  }
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return Math.abs(num) >= item.value;
    });
  const value = item
    ? (Math.abs(num) / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : Math.abs(num).toFixed(digits);
  return isNegative ? `-${value}` : includePlusSymbol ? `+${value}` : value;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getLabelColor2 = (label: string) => {
  let hash = 0;
  for (let i = 0; i < label?.length; i++) {
    hash = label.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color =
    ((hash >> 24) & 0xff).toString(16) +
    ((hash >> 16) & 0xff).toString(16) +
    ((hash >> 8) & 0xff).toString(16) +
    (hash & 0xff).toString(16);
  return "#" + ("000000" + color).slice(-6);
};

export const keywordMatchesSearchWord = (keyword: Keyword, searchWord: string, extraString?: string) => {
  searchWord = searchWord.trim().toLowerCase();
  let stringToSearch = keyword.keyword.toLowerCase();
  stringToSearch +=
    keyword.labels
      ?.map((lbl) => lbl.text)
      .join(" ")
      .toLowerCase() || "";
  if (extraString) {
    stringToSearch += extraString.toLowerCase();
  }
  // exclusion check, return false if the keyword contains the exclusion word
  // const re = RegExp(/(-(\w+))/g);
  const re = RegExp(/-(?:"([^"]+)"|[\p{L}\d_]+)/gu);
  const exclusionWords = searchWord.match(re);
  let excludeAllWithLabels = false;
  if (exclusionWords) {
    for (let i = 0; i < exclusionWords.length; i++) {
      const exclusionWord = exclusionWords[i];
      searchWord = searchWord.replace(exclusionWord, "");
      const exclusionCleaned = exclusionWord.slice(1).replaceAll('"', "");
      if (exclusionCleaned === "exclude_all_with_labels") {
        excludeAllWithLabels = true;
      }
      if (stringToSearch.includes(exclusionCleaned)) {
        return false;
      }
    }
  }
  if (excludeAllWithLabels && keyword.labels?.length) {
    return false;
  }

  // If there are no other rules, return true
  searchWord = searchWord.trim();
  // Only support AND or OR search, but not both
  if (searchWord.includes("&")) {
    // AND search, if any of the AND words are not found, return false
    const andWords = searchWord.split("&");
    for (let i = 0; i < andWords.length; i++) {
      const andWord = andWords[i];
      if (!stringToSearch.includes(andWord)) {
        return false;
      }
    }
  } else {
    // OR search, if none of the OR words are found, return false
    const orWords = searchWord.split("|");
    let containsOrWord = false;
    for (let i = 0; i < orWords.length; i++) {
      const orWord = orWords[i];
      if (stringToSearch.includes(orWord)) {
        containsOrWord = true;
        break;
      }
    }
    if (!containsOrWord) {
      return false;
    }
  }

  return true;
};

export const filterKeywords = (
  keywords: Keyword[],
  filters: AnalysisFilters,
  returnSet?: boolean,
  skipSearchWord: boolean = false
) => {
  const countryCbs = filters.countryFilter?.filter((cb) => cb.checked);
  const languageCbs = filters.languageFilter?.filter((cb) => cb.checked);
  const trendingCbs = filters.trendingFilter?.filter((cb) => cb.checked);
  const kwSet = new Set<Keyword>();
  const filteredKws = keywords.filter((keyword: Keyword) => {
    // country filter
    if (countryCbs?.length > 0) {
      const countryNames = countryCbs.map((cb) => cb.text);
      if (!countryNames.includes(keyword.localization?.location_name)) {
        return false;
      }
    }
    // language filter
    if (languageCbs?.length > 0) {
      const languageNames = languageCbs.map((cb) => cb.text);
      if (!languageNames.includes(keyword.localization?.language_code)) {
        return false;
      }
    }
    // trending filter
    if (trendingCbs?.length > 0) {
      // Trending is a percentage that can range from -100 to infinity
      const trendingNames = trendingCbs.map((cb) => cb.value);
      if (!trendingNames.includes("declining") && keyword.trending <= 0) {
        return false;
      }
      if (!trendingNames.includes("on_the_rise") && keyword.trending > 0 && keyword.trending < 25) {
        return false;
      }
      if (!trendingNames.includes("on_fire") && keyword.trending >= 25 && keyword.trending < 50) {
        return false;
      }
      if (!trendingNames.includes("rocketing") && keyword.trending >= 50) {
        return false;
      }
    }

    // Search word filter
    if (filters.searchWord && !skipSearchWord) {
      // If the keyword or none of the labels contain the search word, return false
      if (keywordMatchesSearchWord(keyword, filters.searchWord) === false) {
        return false;
      }
    }

    // Volume range filter
    if (filters.volumeRange?.min && keyword.volume < filters.volumeRange?.min) {
      return false;
    }
    if (filters.volumeRange?.max && keyword.volume > filters.volumeRange?.max) {
      return false;
    }

    // Position range filter
    if (filters.positionRange?.min && keyword.position < filters.positionRange.min) {
      return false;
    }
    if (filters.positionRange?.max && keyword.position > filters.positionRange.max) {
      return false;
    }
    if (returnSet) kwSet.add(keyword);
    return true;
  });
  return returnSet ? kwSet : filteredKws;
};

export const fixFilters = (filters: AnalysisFilters, viewLabels: ViewLabel[], summary: AnalysisSummary) => {
  if (!filters) filters = generateExampleFilters();

  const structure = viewLabelsToStructure(viewLabels);
  const labelToLevelMap = {} as { [key: string]: number };
  Object.keys(structure).forEach((level: string) => {
    structure[parseInt(level)].forEach((label) => {
      labelToLevelMap[label.text] = parseInt(level);
    });
  });
  const currentCountries: string[] = filters.countryFilter.reduce(
    (acc, cb) => (cb.checked ? [...acc, cb.text] : acc),
    [] as string[]
  );
  if (summary.location_names) {
    filters.countryFilter = summary.location_names.map((loc: string) => ({
      text: loc,
      checked: currentCountries.includes(loc),
    }));
  }
  const currentLanguages: string[] = filters.languageFilter.reduce(
    (acc, cb) => (cb.checked ? [...acc, cb.text] : acc),
    [] as string[]
  );
  if (summary.language_codes) {
    filters.languageFilter = summary.language_codes.map((loc: string) => ({
      text: loc,
      checked: currentLanguages.includes(loc),
    }));
  }
  if (summary.max_volume) filters.volumeRange.rangeMax = summary.max_volume;
  if (summary.min_volume) filters.volumeRange.rangeMax = summary.min_volume;

  const trendingNameValueMap: { [key: string]: any } = {
    "Rocketing +50%": "rocketing",
    "On fire +25-50%": "on_fire",
    "On the rise +1-25%": "on_the_rise",
    Declining: "declining",
  };
  const trendingNameIconMap: { [key: string]: any } = {
    "Rocketing +50%": "fa-regular fa-rocket",
    "On fire +25-50%": "fas fa-fire",
    "On the rise +1-25%": "fa-light fa-chart-mixed",
    Declining: "fa-regular fa-chart-line-down",
  };

  let includeLabels = filters.labelFilters.include.labels as CheckboxState[];
  const labelsInStrure = Object.values(structure)
    .flat()
    .map((label) => label.text);
  const validCurrentIncludeLabels = filters.labelFilters.include.labels.filter((label) =>
    labelsInStrure.includes(label.text)
  );
  const missingLabels = labelsInStrure.filter(
    (label) => !validCurrentIncludeLabels.map((lbl) => lbl.text).includes(label)
  );
  const newLabels = missingLabels.map((label) => ({
    text: label,
    checked: false,
  }));
  if (newLabels.length > 0 || validCurrentIncludeLabels.length !== includeLabels.length) {
    includeLabels = [...validCurrentIncludeLabels, ...newLabels];
  }
  let trendingFilter = filters.trendingFilter;
  if (!trendingFilter?.length) {
    const newFilter = Object.entries(trendingNameValueMap).map(([name, key]) => {
      return {
        text: name,
        icon: trendingNameIconMap[name],
        value: key,
        checked: false,
      };
    });
    trendingFilter = newFilter;
  }
  const newFilter = {
    ...filters,
    labelFilters: {
      include: {
        labels: includeLabels.map((cb) => ({ ...cb, level: labelToLevelMap[cb.text] })),
      },
    },
  };
  return { filters: newFilter };
};

function partition(array: any[], low: number, high: number, field: string): number {
  const pivot = array[high][field];
  let i = low;
  for (let j = low; j < high; j++) {
    if (array[j][field] >= pivot) {
      [array[i], array[j]] = [array[j], array[i]];
      i++;
    }
  }
  [array[i], array[high]] = [array[high], array[i]];
  return i;
}

export function quickSelect(array: any[], k: number, field: string): any[] {
  let low = 0;
  let high = array.length - 1;

  while (low <= high) {
    const pivotIndex = partition(array, low, high, field);
    if (pivotIndex === k - 1) {
      break;
    } else if (pivotIndex > k - 1) {
      high = pivotIndex - 1;
    } else {
      low = pivotIndex + 1;
    }
  }

  const sorted = array.slice(0, k);
  sorted.sort((a, b) => b[field] - a[field]);
  return sorted;
}

export function isSorted(array: any[], field?: string): boolean {
  for (let i = 0; i < array.length - 1; i++) {
    if (field) {
      if (array[i][field] < array[i + 1][field]) {
        return false;
      }
    } else {
      if (array[i] < array[i + 1]) {
        return false;
      }
    }
  }
  return true;
}

export const recommendedLevelForFilters = (filters: AnalysisFilters, viewLabels?: ViewLabel[]): number => {
  if (!filters?.labelFilters) return 1;
  return recommendedLevelForLabelFilters(filters.labelFilters, viewLabels);
};

export const recommendedLevelForLabelFilters = (labelFilters: LabelFilter, viewLabels?: ViewLabel[]) => {
  if (!labelFilters?.include.labels?.filter((cb) => cb.checked)?.length) return 1;
  const labelLevelMap = {} as { [key: string]: number };
  if (viewLabels) {
    viewLabels.forEach((lbl) => (labelLevelMap[lbl.text] = lbl.level));
  }
  let maxInChecked = 0;
  let maxLevel = 1;
  for (const label of labelFilters.include.labels || []) {
    label.level = label.level ?? labelLevelMap?.[label.text];
    if (label.level) {
      if (label.level > maxLevel) {
        maxLevel = label.level;
      }
      if (label.checked && label.level > maxInChecked) {
        maxInChecked = label.level;
      }
    } else {
      return 1;
    }
  }
  return Math.min(maxInChecked + 1, maxLevel);
};

export const levelsNeeded = (viewLabels: ViewLabel[], filterLabels: Set<string>): number[] => {
  if (!filterLabels?.size || !viewLabels?.length) return [1];
  return Array.from(
    viewLabels.reduce((acc: Set<number>, curr: ViewLabel) => {
      if (filterLabels.has(curr.text)) {
        acc.add(curr.level);
      }
      return acc;
    }, new Set())
  );
};

export const includedLabelsAsText = (labelFilters: LabelFilter) =>
  labelFilters.include.labels.reduce((acc: Set<string>, curr: CheckboxState) => {
    if (curr.checked) {
      acc.add(curr.text);
    }
    return acc;
  }, new Set());
