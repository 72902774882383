<template>
  <div
    @click="(e: any) => emit('select', label, e)"
    :class="`inline  ${selected ? 'selected' : 'unselected'}`"
  >
    <v-chip
      @click:close="emit('close', label)"
      :style="{ 'background-color': color ?? getLabelColor(label), color: 'white' }"
      v-if="true"
      :closable="closable"
      :density="density"
      class="label"
    >
      <span :class="`text-truncate label-content`">
        {{ label }}
        <v-tooltip top activator="parent" location="top">{{ label }}</v-tooltip>
      </span>
      <template v-slot:prepend v-if="icons">
        <div :key="icon" v-for="{ icon, tooltip } in icons" style="margin-right: 4px" class="prepend-icon">
          <v-tooltip v-if="tooltip" location="bottom">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" :icon="icon"></v-icon>
            </template>
            {{ tooltip }}
          </v-tooltip>
          <v-icon v-else :icon="icon"></v-icon>
        </div>
      </template>
      <template #close v-if="overrideClose">
        <v-icon
          :size="density === 'dense' ? '12' : undefined"
          v-if="closable"
          icon="mdi:mdi-close-circle"
          @click.stop="emit('close', label)"
        />
      </template>
    </v-chip>
  </div>
</template>
<style scoped>
.prepend-icon {
  display: flex;
  align-items: center;
  background-color: orange;
  justify-content: center;
  border-radius: 20px;
  text-align: center;
  /* padding: 4px; */
  margin: 4px;
  margin-left: -4px;
}
.prepend-icon i {
  margin: 5px;
  font-size: 12px;
  line-height: 12px;
  vertical-align: middle;
}
.label span {
  max-width: 250px;
}
.label {
  cursor: pointer;
}

.label {
  margin: 2px;
}

p {
  color: white;
  font-size: 12px;
}
.unselected {
  filter: opacity(0.4) brightness(0.8) grayscale(0.8);
}
</style>

<script lang="ts" setup>
import { defineProps, defineEmits, PropType } from "vue";
import { useLabelColor } from "@/composables/useLabelColor";
const { getLabelColor } = useLabelColor();
const emit = defineEmits(["select", "close"]);
defineProps({
  label: {
    type: String,
    required: true,
  },
  selected: {
    type: Boolean,
    required: false,
    default: true,
  },
  icons: { type: Array as PropType<{ icon: string; tooltip?: string }[]>, required: false },
  closable: {
    type: Boolean,
    required: false,
    default: true,
  },
  overrideClose: {
    type: Boolean,
    required: false,
    default: false,
  },
  density: {
    type: String,
    required: false,
    default: "default",
  },
  color: {
    type: String,
    required: false,
  },
});
</script>
