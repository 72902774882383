<template>
  <div id="action-bar">
    <div class="view-name">
      <p class="view-title s strong">
        {{ viewName }}
        <span class="chip google-only" v-if="analysis?.volume_field === 'monthly_google_searches'">
          Google only
        </span>
      </p>
    </div>
    <div class="action-container" v-show="!loading">
      <ActionIcon
        icon="fa-regular fa-gear"
        :onToggle="() => toggleSection('configure')"
        :toggled="toggledSection === 'configure'"
      />
      <ActionIcon
        icon="fa-solid fa-bars"
        :onToggle="() => toggleSection('manage')"
        :toggled="toggledSection === 'manage'"
        v-if="userIsNotViewer"
      />
      <div class="content" v-if="toggledSection" :class="expanded ? 'expanded' : ''">
        <div
          :class="`drawer-open-button-left  ${expanded ? 'nav-btn-open-left' : 'nav-btn-close-left'}`"
          @click.stop="expanded = !expanded"
        >
          <div style="height: 100%" icon>
            <v-icon style="height: 100%">{{
              !expanded ? "mdi:mdi-chevron-left" : "mdi:mdi-chevron-right"
            }}</v-icon>
          </div>
        </div>
        <div class="paper-background" style="height: 100%">
          <div v-if="toggledSection === 'configure'" class="structure column-flex">
            <div class="source-labels">
              <SourceLabelEditor
                :labels="companyLabels"
                :source-labels="sourceLabels"
                :editing-allowed="editMode"
                @save="handleSourceLabelSave"
              />
            </div>
            <div v-if="editMode" class="level-editor">
              <LevelEditor
                :view-labels="viewLabels"
                :source-labels="sourceLabels"
                @close="editMode = false"
                @save="handleStructureSave"
              />
            </div>
            <div v-show="!editMode" class="level-editor maire-scrollbar">
              <span class="s">Click the labels to filter</span>
              <LabelFilters
                :selectedLevel="selectedLevel"
                :changeLevel="changeLevel"
                :cancelOverride="cancelOverride"
                :overrideRecommendedLevel="overrideRecommendedLevel"
                :viewId="viewId"
              />
            </div>
            <div v-show="!editMode" class="actionbar-bottom-buttons">
              <MaireButton
                text="Edit analysis view"
                :disabled="!userIsNotViewer"
                disabled-message="You don't have access to edit this analysis"
                inverse
                icon="fal fa-chart-pie"
                class="save-analysis-button"
                @click="editMode = true"
                variant="contained"
                comfortable
              />
            </div>
          </div>
          <div v-if="toggledSection === 'labels'"></div>
          <div v-if="toggledSection === 'manage'">
            <CreateAnalysisFilterModal
              v-if="saveModalOpen && analysis"
              :type="saveType"
              :details="newAnalysis ?? saveDetails"
              :open="saveModalOpen"
              :onSave="handleSave"
              :onClose="toggleModal"
            />
            <div
              :key="action.text"
              class="manage-action pointer"
              v-for="action in actions"
              @click="action.action"
            >
              <v-icon>{{ action.icon }}</v-icon
              ><span class="xs">{{ action.text }}</span>
            </div>
            <ShareItemModal
              v-if="shareData"
              :item="shareData.item"
              :item-type="shareData.type"
              :clear-item="clearItemToShare"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.view-title {
  color: white;
  margin: auto;
  width: 50%;
  position: absolute;
  padding: 10px 10px 10px 100px;
  display: inline-block;
}
#action-bar .manage-action {
  display: flex;
  align-items: center;
  padding: 12px 12px;
}
#action-bar .manage-action:hover {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.1);
}
#action-bar .manage-action span {
  margin-left: 12px;
}
#action-bar {
  position: fixed;
  z-index: 1005;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 1);
}
.action-container {
  display: flex;
  align-items: center;
  height: 100%;
  float: right;
  padding: 0 12px;
  margin-right: 12px;
}
.content {
  position: absolute;
  right: 0px;
  top: 52px;
  align-items: center;
  height: calc(100vh - 42px);
  overflow-y: scroll;
  width: 350px;
  overflow: hidden;
  padding: 0px 0px 0px 12px;
}
.expanded {
  width: 800px;
}
.source-labels {
  flex: 0 0 0%; /* Takes up 20% of the parent height */
  background-color: #f7f7f7;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
}
.level-editor {
  flex: 1 1 50%;
  overflow: auto;
}
.actionbar-bottom-buttons {
  flex: 0 0 auto; /* Stays at the bottom*/
  align-content: end;
  padding-right: 10px;
}
.actionbar-bottom-button {
  display: flex;
  align-items: center;
}
</style>
<script lang="ts" setup>
import { computed, Ref, ref, defineProps, onMounted } from "vue";
import ActionIcon from "@/components/ActionBar/ActionIcon.vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import { Label, ViewLabel } from "@/store/modules/labels/types";
import CreateAnalysisFilterModal from "../CreateAnalysisFilterModal/CreateAnalysisFilterModal.vue";
import ShareItemModal from "../ShareItemModal/ShareItemModal.vue";
import LabelFilters from "@/components/LabelFilters/LabelFilters.vue";
import {
  Analysis,
  AnalysisCreate,
  AnalysisDetails,
  AnalysisUpdate,
  View,
} from "@/store/modules/analysis/types";
import { useStore } from "@/store";
import { watch } from "vue";
import LevelEditor from "./LevelEditor.vue";
import SourceLabelEditor from "./SourceLabelEditor.vue";

const props = defineProps({
  viewId: {
    type: Number,
    required: true,
  },
  selectedLevel: {
    type: Number,
    required: true,
    default: 1,
  },
  overrideRecommendedLevel: {
    type: Boolean,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  changeLevel: {
    type: Function,
    required: true,
  },
  cancelOverride: {
    type: Function,
    required: true,
  },
});
const store = useStore();
// const hovering = ref(false);
const expanded = ref(false);

const structureMissing = ref(false);
const saveType: Ref<"Analysis" | "View"> = ref("Analysis");
const saveDetails: Ref<Partial<AnalysisUpdate>> = ref({});
const newAnalysis: Ref<AnalysisCreate | undefined> = ref(undefined);
const editMode = ref(true);

const toggledSection = ref("configure");

const saveModalOpen = ref(false);
const shareData: Ref<undefined | { item: Analysis | View; type: "analysis" | "view" }> = ref(undefined);

const analysis = computed(() => {
  const afId = store.state.analysis.currentAnalysis?.id;
  return store.state.analysis.analyses?.find((x) => x.id === afId);
});
const viewName = computed(() => {
  return analysis.value?.name && view.value?.name ? `${analysis.value?.name}  - ${view.value?.name}` : "";
});
const viewLabels = computed(() => store.state.labels.viewLabels?.[props.viewId]);
const labelsById = computed(() => store.state.labels.labelsById);
const companyLabels = computed(() => Object.values(labelsById.value));
const sourceLabels = computed(
  () => analysis.value?.labels?.map((lbl) => labelsById.value?.[lbl]).filter(Boolean) ?? []
);

const handleStructureSave = (viewLabelsNew: ViewLabel[]) => {
  store.dispatch("labels/saveViewLabels", {
    viewId: props.viewId,
    viewLabels: viewLabelsNew,
  });
};

const handleSourceLabelSave = (newSourceLabels: Label[]) => {
  store.dispatch("analysis/updateSourceLabels", {
    analysisId: analysis.value?.id,
    labelIds: newSourceLabels.map((a) => a.id),
  });
};

const view = computed(() => store.state.analysis.views?.[props.viewId]);

const actions = computed(() => {
  const actions = [];
  if (analysis.value?.edit_access) {
    actions.push({
      text: "Save (Update) Analysis",
      action: () => {
        if (!analysis.value) return;
        const { id, name, description } = analysis.value;
        saveDetails.value = { id, name, description };
        saveType.value = "Analysis";
        toggleModal();
      },
      icon: "fa-regular fa-save",
    });
  }
  actions.push({
    text: "Save as new Analysis",
    action: () => {
      if (!analysis.value) return;
      newAnalysis.value = {
        name: analysis.value.name + " (Copy)",
        description: analysis.value.description,
        labels: analysis.value?.labels,
      };
      saveType.value = "Analysis";
      toggleModal();
    },
    icon: "fa-regular fa-floppy-disk-circle-arrow-right",
  });
  if (userHasOwnerAccess(analysis.value)) {
    actions.push({
      text: "Share Analysis",
      action: () => {
        shareData.value = { item: analysis.value as Analysis, type: "analysis" };
      },
      icon: "fa-regular fa-share-nodes",
    });
  }
  if (view.value?.edit_access) {
    actions.push({
      text: "Save (Update) View",
      action: () => {
        saveDetails.value = view.value;
        saveType.value = "View";
        toggleModal();
      },
      icon: "fa-regular fa-save",
    });
  }
  actions.push({
    text: "Create a new View",
    action: () => {
      saveDetails.value = {
        ...view.value,
        id: undefined,
        name: view.value?.name + " (Copy)",
      };
      saveType.value = "View";
      toggleModal();
    },
    icon: "fa-regular fa-floppy-disk-circle-arrow-right",
  });
  if (userHasOwnerAccess(view.value)) {
    actions.push({
      text: "Share View",
      action: () => {
        shareData.value = { item: view.value, type: "view" };
      },
      icon: "fa-regular fa-share-nodes",
    });
  }
  return actions;
});

const userIsNotViewer = computed(() => {
  return store.state.user?.type !== "viewer";
});

const resetUI = () => {
  toggledSection.value = "configure";
  if (viewLabels.value && Object.keys(viewLabels.value)?.length === 0 && userIsNotViewer.value) {
    structureMissing.value = true;
    editMode.value = true;
  } else {
    editMode.value = false;
  }
};

const toggleSection = (section: string) => {
  if (toggledSection.value === section) {
    toggledSection.value = "";
    store.dispatch("rightNavbarOpen", false);
  } else {
    store.dispatch("rightNavbarOpen", true);
    toggledSection.value = section;
  }
};

const toggleModal = () => {
  if (saveModalOpen.value) {
    newAnalysis.value = undefined;
    saveDetails.value = {} as AnalysisDetails;
  }
  saveModalOpen.value = !saveModalOpen.value;
};

const handleSave = (details: AnalysisDetails) => {
  const payload = { ...saveDetails.value, ...details };
  let action = "analysis/createOrUpdateAnalysis";
  if (saveType.value === "View") {
    action = payload.id ? "analysis/updateView" : "analysis/createView";
  }
  store.dispatch(action, payload);
  newAnalysis.value = undefined;
  saveDetails.value = {} as AnalysisDetails;
};

const userHasOwnerAccess = (analysisOrView?: Analysis | View) => {
  const user = store.state.user;
  return analysisOrView?.user_id === user.id || user.type === "company_admin";
};

const clearItemToShare = () => {
  shareData.value = undefined;
};

watch(viewLabels, resetUI, { immediate: true });
onMounted(() => store.dispatch("rightNavbarOpen", true));
</script>
