<template>
  <MaireDialog
    :onClose="() => (deletionModalOpen = false)"
    :open="deletionModalOpen"
    :description="`Are you sure you
  want to delete the discovery &quot;${discoveryToDelete?.name}&quot;? This cannot be undone!`"
    confirmText="Delete"
    :fn="deleteDiscovery"
  />
  <div v-if="discoveryToEdit">
    <MaireDialog
      :title="`Edit ${discoveryToEdit.name}`"
      :open="editModalOpen"
      description="Edit the name and color of the label"
      :onClose="() => (editModalOpen = false)"
      confirmText="Save"
      :fn="createOrUpdateDiscovery"
    >
      <p class="s strong my-2">Discovery name</p>
      <v-text-field
        hide-details
        density="compact"
        type="string"
        variant="outlined"
        v-model="newName"
        required
      >
      </v-text-field>
    </MaireDialog>
  </div>
  <div class="label-list maire-scrollbar horizontal-padding">
    <div class="table-content">
      <div>
        <div class="mb-3"></div>
        <div class="search_container">
          <SearchBar @set="searchWordChanged" />
        </div>
      </div>
      <DataTable
        :items="discoveries"
        :fields="fields"
        hide-actions
        :actions="actions"
        :handleRowClick="handleRowClick"
      />
    </div>
  </div>
</template>
<style scoped>
.search_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0px;
  width: 400px;
  float: right;
}
#create-label-button {
  width: 230px;
  float: left;
  position: relative;
  margin-bottom: 12px;
  z-index: 2;
}
.label-list {
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 0px !important;
}

.table-content {
  max-height: 100%;
  left: 0px;
  right: 0px;
  margin: auto;
}
</style>
<script lang="ts" setup>
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { computed, Ref, ref } from "vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import SearchBar from "@/components/DataTable/SearchBar.vue";
import { keywordMatchesSearchWord } from "@/components/helpers/helpers";
import { Label } from "@/store/modules/labels/types";
import { Discovery } from "@/store/modules/discovery/types";
import { countryISOCode } from "@/components/KeywordTable/KeywordTable.vue";
import LabelCell from "@/components/DataTable/DataTableCells/LabelCell/LabelCell.vue";
import StrongCell from "@/components/DataTable/DataTableCells/StrongCell.vue";
import ChipCell from "@/components/DataTable/DataTableCells/ChipCell/ChipCell.vue";
import CheckboxCell from "@/components/DataTable/DataTableCells/CheckboxCell/CheckboxCell.vue";
const store = useStore();

const toggleDiscovery = async (discovery: Discovery, isActive: boolean) => {
  if (!discovery?.id) return;
  await store.dispatch("discovery/updateDiscovery", {
    id: discovery.id,
    updates: { is_active: isActive },
  });
};

const fields = [
  {
    field: "name",
    name: "Discovery",
    width: 15,
    component: () => StrongCell,
    allow_filtering: true,
    filtering_key: "name",
    aggregation: "count",
  },
  {
    field: "domain",
    name: "Domain",
    width: 10,
    aggregation: "count_distinct",
    renderer: (value: string) => value,
    postfix: " unique",
  },
  {
    field: "localization",
    name: "Locale",
    width: 10,
    aggregation: "count_distinct",
    postfix: " unique",
    // @ts-ignore
    renderer: (value: string) => value,
  },
  {
    field: "descriptive_words",
    name: "Descriptive words",
    width: 20,
    // @ts-ignore
    component: () => ChipCell,
    componentProps: { color: "#8200ff", density: "compact" },
  },
  {
    field: "labels",
    name: "Labels",
    filtering_key: "text",
    width: 20,
    // @ts-ignore
    component: () => LabelCell,
    componentProps: { editable: false, density: "compact" },
  },
  {
    field: "is_active",
    name: "Automatic discovery",
    width: 10,
    // @ts-ignore
    component: () => CheckboxCell,
    componentProps: {
      icon: "far fa-clock",
      fn: toggleDiscovery,
      isToggled: (d: Discovery) => d?.is_active,
      tooltipTitle: "Automatic discovery",
      tooltipDescription:
        " When selected, the discovery will periodically be run automatically and checked for matching labels.",
    },
  },
];
const router = useRouter();
const handleRowClick = (e: any, item: Label) => {
  const isLabel = e.target.className.includes("label")
  const isInput = e.target.localName?.includes("input");
  const isActionIcon = e.target.className.includes("mdi") || e.target.className.includes("fa");
  if (isLabel || isInput || isActionIcon) return;
  router.push(`/keywords?discovery=${item.id}`);
};

const discoveries = computed(() =>
  Object.values(store.state.discovery.discoveries)
    .map((discovery) => ({
      ...discovery,
      localization: `${countryISOCode(discovery.location ?? "").toUpperCase()} / ${discovery.language}`,
    }))
    .filter((label) =>
      keywordMatchesSearchWord(
        // @ts-ignore
        { keyword: label.name },
        searchWord.value,
        JSON.stringify({ ...label, id: undefined })
      )
    )
);
const deletionModalOpen = ref(false);
const editModalOpen = ref(false);
const newName = ref("");
const discoveryToDelete: Ref<Discovery | undefined> = ref();
const discoveryToEdit: Ref<Discovery | undefined> = ref();
const deleteDiscovery = async () => {
  deletionModalOpen.value = false;
  if (discoveryToDelete.value) {
    await store.dispatch("discovery/delete", discoveryToDelete.value);
    router.push("/discoveries");
  }
};
const searchWord = ref("");
const searchWordChanged = (val: string) => {
  searchWord.value = val;
};
const createOrUpdateDiscovery = async () => {
  editModalOpen.value = false;
  await store.dispatch("discovery/createOrUpdateDiscovery", {
    ...discoveryToEdit.value,
    name: newName.value,
  });
  discoveryToEdit.value = undefined;
  newName.value = "";
};
const actions = [
  {
    icon: "fa-regular fa-pencil",
    name: "Edit name",
    fn: (d: Discovery) => {
      newName.value = d.name;
      discoveryToEdit.value = d;
      editModalOpen.value = true;
    },
  },
  {
    icon: "fa-regular fa-trash",
    name: "Delete",
    fn: (item: Discovery) => {
      discoveryToDelete.value = item;
      deletionModalOpen.value = true;
    },
  },
];
</script>
