<template>
  <v-container>
    <v-row class="text-start">
      <v-col cols="10">
        <div class="view">
          <div class="performance-title-container">
            <h3 class="mb-3 primary text-primary" style="display: inline">Your performance statistics</h3>
            <div class="search_container">
              <SearchBar />
            </div>
          </div>
          <MaireTab :tabs="tabs" :selectedTab="selectedTab" :onSelect="(tab: string) => selectedTab = tab">
            <div v-if="gsc_countries">
              <v-select
                density="compact"
                v-model="selectedCountry"
                variant="outlined"
                item-value="country"
                item-title="formattedName"
                :items="gsc_countries"
              />
            </div>
          </MaireTab>
          <div class="performance_table" v-if="selectedTab === 'Keyword Table' && performance_data">
            <DataTable
              :items="performance_data"
              :fields="fields"
              :handleRowClick="handleRowClick"
              :searchFields="['keyword', 'page', 'domain']"
              :hideActions="true"
            />
          </div>
          <div class="insights" v-if="selectedTab === 'Insights' && selectedCountry !== undefined">
            <v-row>
              <v-col cols="4" class="widget">
                <GooglePresenceAlternateWidget />
              </v-col>
              <v-col cols="8" class="widget">
                <OrganicTrafficWidget />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-col cols="9"> </v-col>
  </v-container>
</template>
<style scoped>
.performance-title-container {
  padding: 12px 0px;
  width: 100%;
}
.search_container {
  margin-top: 4px;
  width: 400px;
  position: relative;
  float: right;
  display: inline;
  margin-top: 16px;
  margin-bottom: -16px;
}
.view {
  padding: 12px 0px;
  margin-top: 30px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

import StrongCell from "@/components/DataTable/DataTableCells/StrongCell.vue";
import ProgressCell from "@/components/DataTable/DataTableCells/ProgressCell.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import SearchBar from "@/components/DataTable/SearchBar.vue";
import MaireTab from "@/components/MaireTab/MaireTab.vue";
import GooglePresenceAlternateWidget from "@/components/Widgets/GooglePresenceAlternateWidget/GooglePresenceAlternateWidget.vue";
import OrganicTrafficWidget from "@/components/Widgets/OrganicTrafficWidget/OrganicTrafficWidget.vue";
import { keywordMatchesSearchWord, numberFormatter } from "@/components/helpers/helpers";
import { PerformanceData } from "@/store/index";
import { Field } from "@/types";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "SettingsView",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  data: () => ({
    tabs: [
      { key: "Insights", title: "Insights" },
      { key: "Keyword Table", title: "Keyword Table" },
    ],
    selectedCountry: undefined,
    selectedTab: "Insights",
    fields: [
      {
        name: "Domain",
        field: "domain",
        width: 11,
        renderer: (value: string) => value,
        aggregation: "count_distinct",
        tooltip: "The domain of the page",
      },
      {
        name: "Page",
        field: "page",
        width: 17,
        renderer: (value: string) => (value ? new URL(value).pathname : value),
        aggregation: "count_distinct",
        tooltip: "Page URL",
      },
      {
        name: "Search term",
        field: "keyword",
        width: 10,
        renderer: (value: string) => value,
        aggregation: "count_distinct",
        tooltip: "Search term where ranking was found",
      },
      {
        name: "Position",
        field: "position",
        width: 7,
        component: () => StrongCell,
        aggregation: "average",
        tooltip:
          "Position of the page in the search results. Either average position GSC or your position looking at an individual search result page.",
      },
      {
        name: "Potential reached",
        field: "potential_reached",
        component: () => ProgressCell,
        aggregation: "weighted_average_impressions",
        tooltip: "Proportion of potential traffic reached (estimated)",
        width: 10,
      },
      {
        name: "CTR",
        field: "ctr",
        renderer: (value: number) => `${(value * 100).toFixed(2)}%`,
        aggregation: "weighted_average_impressions",
        width: 5,
        tooltip: "Click-through rate (estimated)",
      },
      {
        name: "Volume",
        field: "volume",
        renderer: (value: number) => numberFormatter(value, 2),
        aggregation: "weighted_average_impressions",
        width: 6,
        tooltip: "Number of searches per month",
      },
      {
        name: "Clicks (est)",
        field: "estimated_clicks",
        renderer: (value: number) => numberFormatter(value, 2),
        aggregation: "sum",
        width: 7,
        tooltip: "Number of clicks per month (estimated))",
      },
      {
        name: "Clicks (GSC)",
        field: "clicks",
        renderer: (value: number) => numberFormatter(value, 2),
        aggregation: "sum",
        width: 8,
        tooltip: "Number of clicks in GSC",
      },
      {
        name: "Impressions (GSC)",
        field: "impressions",
        renderer: (value: number) => numberFormatter(value, 2),
        aggregation: "sum",
        width: 11,
        tooltip: "Number of impressions in GSC",
      },
      {
        name: "Date",
        field: "fetch_date",
        width: 8,
        renderer: (value: string) => new Date(value).toLocaleDateString(),
        tooltip: "Date when page ranking was found",
      },
    ] as Field[],
  }),

  watch: {
    selectedCountry() {
      this.store.dispatch("gsc/fetch_gsc_chart_data", this.selectedCountry);
      this.store.dispatch("fetch_performance", this.selectedCountry);
    },
  },
  mounted() {
    if (!this.selectedCountry) {
      this.store.dispatch("gsc/fetch_gsc_countries");
    }
    if (this.selectedCountry && !this.gsc_chart_data) {
      this.store.dispatch("gsc/fetch_gsc_chart_data", this.selectedCountry);
    }
    if (this.selectedCountry && !this.performance_data) {
      this.store.dispatch("fetch_performance", this.selectedCountry);
    }
    this.store.commit("analysis/setSearchWord", "");
  },
  computed: {
    user() {
      return this.store.state.user;
    },
    isAdmin() {
      return this.user.type === "admin";
    },
    company() {
      return this.store.state.company;
    },
    performance_data() {
      // @ts-ignore
      return this.store.state.performance_data?.filter((item) =>
        // @ts-ignore
        keywordMatchesSearchWord(item, this.store.state.searchWord, `${item.page} ${item.domain}`)
      );
    },
    gsc_chart_data() {
      return this.store.state.gsc.gsc_chart_data;
    },
    gsc_countries() {
      const country_datas = this.store.state.gsc.gsc_countries.map((countryData) => {
        return {
          ...countryData,
          country: countryData.country || "Unknown",
          formattedName: `${countryData.country || "Unknown"} (${countryData.clicks} clicks)`,
        };
      });
      return [{ country: "", formattedName: "All countries" }, ...country_datas];
    },
  },
  methods: {
    handleRowClick(e: any, item: PerformanceData) {
      const isActionIcon = e.target.className.includes("mdi");
      if (!isActionIcon && item.keyword_id) {
        this.router.push(`/keywords/${item.keyword_id}`);
      } else if (!isActionIcon && !item.keyword_id) {
        this.store.dispatch(
          "sendErrorMessage",
          "Keyword details not found. Consider searching it in the discovery page!"
        );
      }
    },
  },
  components: {
    DataTable,
    GooglePresenceAlternateWidget,
    OrganicTrafficWidget,
    SearchBar,
    MaireTab,
  },
});
</script>
