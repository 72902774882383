<template>
  <div style="margin-bottom: 52px" v-if="searchOpen || selectedDiscovery?.id">
    <DiscoveryActionBar
      @title-change="handleDiscoveryNameChange"
      @close-discovery="handleDiscoveryClear"
      @toggle="handleDiscoveryToggle"
      :title="getDiscoveryName"
      :discovery="discoveryInState"
      :is-draft="isDraft"
      :recent-success="recentNameChangeSuccess"
    />
  </div>
  <div>
    <DiscoverySearch
      :discovery="selectedDiscovery"
      @save="handleDiscoverySave"
      @discover="discover"
      @select="handleDiscoverySelect"
      @clear="handleDiscoveryClear"
      @nameSuggestion="handleNameSuggestion"
      @toggle="(open: boolean) => searchOpen = open"
      @update="handleDiscoveryFieldChange"
      @changed="(val: boolean) => discoveryChanged = val"
    />
  </div>
  <v-container v-if="selectedDiscovery?.id || true" id="main-analysis-view" class="horizontal-padding">
    <div id="configuration-section">
      <div
        class="inline-block"
        style="width: 170px; padding: 12px 0px; height: 100%; margin-right: 12px; align-self: center"
        id="prediction-button"
        v-if="drawersOpen.includes('graphs')"
      >
        <MaireButton
          :compact="true"
          @click="fetchTimelinePredictions"
          text="Fetch predictions"
          appendIcon="far fa-crystal-ball"
          :isLoading="!!loadingTimelineData"
          :disabled="!allowPredicting"
        />
      </div>
      <div class="inline-block custom-density" style="width: 200px" v-if="canEditLinkedLabels || true">
        <MaireChipsInput
          label="Link existing label(s)"
          icon="far fa-tag"
          :itemList="labels"
          :closable="false"
          :selected="selectedDiscovery.labels?.map((lbl) => lbl.id)"
          transparent
          persistent-placeholder
          placeholder="Select label(s)"
          width=""
          @add="handleLinkedLabelAdd"
          field="text"
          hideChips
          multiple
        />
      </div>
      <IconToggle
        @toggle="toggleGraphs"
        :state="drawersOpen.includes('graphs') ? 1 : 0"
        :states="graphToggleStates"
      />
    </div>
    <v-row v-if="(selectedDiscovery.labels ?? []).length > 0">
      <v-col cols="12">
        <div class="linked-labels-section paper-background-flat-no-padding">
          <v-container>
            <p class="s strong">Labels linked to discovery</p>
            <div id="label-chips-container" style="margin-left: -4px">
              <MaireChips
                density="default"
                :items="selectedDiscovery.labels ?? []"
                :field="'text'"
                :prependFn="getIconsForLabel"
                @click="handleLabelChipClick"
                @close="handleLinkedLabelRemove"
              />
            </div>
            <div id="trigger-labeling-button">
              <MaireButton
                appendIcon="far fa-robot"
                inverse
                text="Trigger labeling"
                :disabled="!(!discoveryChanged && selectedDiscovery.labels && selectedDiscovery?.id)"
                :isLoading="labelingInProgress"
                disabledMessage="Save the discovery before triggering labeling."
                :onClick="triggerLabeling"
                compact
              />
            </div>
          </v-container>
        </div>
      </v-col>
    </v-row>
    <div v-show="isLoading" class="wait-animation">
      <wait-animation />
    </div>
    <div v-show="!isLoading">
      <div class="drawer" v-show="!isLoading">
        <div
          class="drawer-content graphs"
          :class="{ active: drawersOpen.includes('graphs') }"
          v-if="!isLoading && dataDateRange?.latestYear"
        >
          <v-row>
            <v-col
              :cols="widget.col"
              :md="12"
              :sm="12"
              :lg="6"
              :xl="widget.col"
              :key="widget.name"
              v-for="widget in widgets"
            >
              <MaireChart
                :name="widget.name"
                :id="widget.id"
                :volumes="widget.volumes"
                :dataDateRange="dataDateRange"
                :initialType="widget.type"
                :defaultField="widget.defaultField"
                :label="widget.name"
                :loading="!!widget.processing"
                :itemType="widget.itemType"
                :defaultTimeframeText="widget.defaultTimeframeText"
                :discovery="true"
                :kwIds="widget.kwIds"
                :params="widget.params"
                :hideIfEmpty="widget.hideIfEmpty"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row v-if="!isLoading">
        <v-col cols="12" class="container-margin">
          <div>
            <div class="" v-if="selectedDiscovery.labels?.length">
              <MaireTab
                :onSelect="(tab: string) => selectedTab = tab"
                :selectedTab="selectedTab"
                fillWidthContent
                :tabs="tabs"
              >
                <SearchBar />
              </MaireTab>
            </div>
            <div v-else style="width: 30%; float: right"><SearchBar /></div>
            <div v-show="!isLoading">
              <keyword-table
                :keywords="visibleKeywords"
                :externalTotals="false"
                :hideFields="selectedDiscovery.labels?.length ? ['labels'] : ['discovery_labels']"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped>
#prediction-button {
  width: 150px;
}
#trigger-labeling-button {
  width: 150px;
  max-width: 20%;
  float: right;
  margin-top: 8px;
}
#configuration-section {
  display: flex;
  padding: 12px 0px;
  width: 100%;
}
.linked-labels-section {
  background-color: white;
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
}
#label-chips-container {
  width: 80%;
  display: inline-block;
}
.center-info {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  text-align: center;
  transform: translate(0%, 50%);
}

.center-info p {
  text-align: center;
}
.drawer-content {
  display: none;
  /* margin-top: 24px; */
}
.drawer-content.active {
  display: block;
}
.drawer-chevron {
  position: relative;
  cursor: pointer;
  float: right;
}
.drawer-bar {
  width: 100%;
  cursor: pointer;
}
.container-margin {
  margin-top: 12px;
  margin-bottom: 12px;
}
.loading {
  filter: blur(4px) brightness(100%);
}
</style>
<script lang="ts" setup>
import KeywordTable from "@/components/KeywordTable/KeywordTable.vue";
import SearchBar from "@/components/DataTable/SearchBar.vue";
import DiscoverySearch from "@/components/DiscoverySearch/DiscoverySearch.vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import MaireChart from "@/components/MaireChart/MaireChart.vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import { filterKeywords } from "@/components/helpers/helpers";
import { UserSearch, WidgetType, useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { Keyword } from "@/store/modules/keywords/types";
import { computed, ref, watch, onMounted, Ref } from "vue";
import * as api from "@/api";
import DiscoveryActionBar from "@/components/ActionBar/DiscoveryActionBar.vue";
import { Discovery } from "@/store/modules/discovery/types";
import { Label, LabelRuleDetails } from "@/store/modules/labels/types";
import MaireChipsInput from "@/components/MaireChipsInput/MaireChipsInput.vue";
import MaireChips from "@/components/MaireChipsInput/MaireChips.vue";
import IconToggle from "@/components/IconToggle/IconToggle.vue";
import MaireTab from "@/components/MaireTab/MaireTab.vue";

const store = useStore();
const route = useRoute();

if (store.state.user?.type === "viewer") {
  const router = useRouter();
  router.push({ path: "analysis" });
}
const selectedDiscovery: Ref<Partial<Discovery>> = ref({});
const discoveryInState = computed(() =>
  store.state.discovery.discoveries.find((d) => d.id === selectedDiscovery.value.id)
);
const discoveryFields: Ref<Partial<Discovery>> = ref({});
const discoveryChanged = ref(false);
const isDraft = ref(true);
const searchOpen = ref(false);
const allowPredicting = ref(false);
const loadingTimelineData = ref(false);
const timelineStats = ref({});
const getDiscoveryName = computed(() =>
  selectedDiscovery.value.name ? selectedDiscovery.value.name : nameSuggestion.value ?? "Draft"
);
const nameSuggestion = ref("");
const recentNameChangeSuccess = ref(false);
const tabs = ref([
  { key: "captured", title: "Captured" },
  { key: "uncaptured", title: "Uncaptured" },
]);
const selectedTab = ref("captured");
const fetchTimelinePredictions = async () => {
  if (filteredIds.value.length === 0) {
    return;
  }
  loadingTimelineData.value = true;
  const r = await api.fetchPredictions({ series: timelineStats.value });
  allowPredicting.value = false;
  loadingTimelineData.value = false;
  timelineStats.value = r.data;
};
const fetchTimelineStats = async (predictions?: boolean) => {
  if (filteredIds.value.length === 0) {
    return;
  }
  loadingTimelineData.value = true;
  const r = await api.fetchKwTimelineStats(filteredIds.value, predictions);
  allowPredicting.value = true;
  loadingTimelineData.value = false;
  timelineStats.value = r.data;
};
const graphToggleStates = ref({
  0: { icon: "far fa-eye", text: "Show graphs" },
  1: { icon: "far fa-eye-slash", text: "Hide graphs" },
});
const toggleGraphs = () => {
  if (drawersOpen.value.includes("graphs")) {
    drawersOpen.value = drawersOpen.value.filter((v) => v !== "graphs");
  } else {
    drawersOpen.value.push("graphs");
  }
};
const isLoading = computed(() => store.state.loading.loadingKeywords);
const drawersOpen = ref(["graphs"] as string[]);
const keywords = computed(() => store.state.keywords.discoveryKeywords);

const filters = computed(() => store.state.analysis.currentFilters);
const filteredKeywords = computed(() => {
  // @ts-ignore
  const kws = filterKeywords(keywords.value, filters.value, false) as Array<Keyword>;
  return kws;
});
const visibleKeywords = computed(() => {
  if (!selectedDiscovery.value.labels?.length) return filteredKeywords.value;
  const showCaptured = selectedTab.value === "captured";
  return filteredKeywords.value.filter((kw) => kw?.captured === showCaptured);
});
const filteredIds = computed(() => filteredKeywords.value.map((kw) => kw.id));

const widgets = computed(() => {
  return [
    {
      id: 6,
      type: WidgetType.TimelineChart,
      name: "Timeline",
      volumes: timelineStats.value,
      defaultField: "",
      fields: [],
      processing: loadingTimelineData.value,
      col: 4,
    },
    {
      id: 3,
      type: WidgetType.BarChart,
      kwIds: filteredIds.value,
      name: "Trending by keyword",
      defaultTimeframeText: "Latest",
      defaultField: "trending",
      col: 4,
      itemType: "keyword",
    },
    {
      id: 55,
      type: WidgetType.BarChart,
      kwIds: filteredIds.value,
      name: "Volume by keyword",
      defaultTimeframeText: "Last 12 months",
      defaultField: "volume",
      col: 4,
      itemType: "keyword",
    },
    {
      id: 4,
      type: WidgetType.BarChart,
      kwIds: filteredIds.value,
      name: "Growth by keyword",
      defaultTimeframeText: "Last 12 months",
      defaultField: "growth",
      col: 4,
      itemType: "keyword",
    },
    {
      id: 4,
      type: WidgetType.BarChart,
      kwIds: filteredIds.value,
      name: "Absolute growth by keyword",
      defaultTimeframeText: "Last 12 months",
      defaultField: "absoluteGrowth",
      col: 4,
      itemType: "keyword",
    },
    {
      id: 5,
      type: WidgetType.BarChart,
      kwIds: filteredIds.value,
      defaultField: "volume",
      params: { include_only_new: true },
      hideIfEmpty: true,
      name: "New keywords",
      col: 6,
      itemType: "keyword",
    },
  ];
});

// let prevProps: any = {};
const dataDateRange = ref();

watch(
  filteredKeywords,
  (newValue, oldValue) => {
    const oldIds = new Set(oldValue?.map((kw) => kw.id) || []);
    // set of new ids
    const newIds = new Set(newValue?.map((kw) => kw.id));
    if (newValue.length === 0) {
      timelineStats.value = [];
      return;
    }
    const dt = new Date(newValue?.[0]?.until_date);
    if (dt) {
      const untilDate = new Date(dt);
      const fourYearsAgo = new Date(dt);
      fourYearsAgo.setFullYear(untilDate.getFullYear() - 4);
      fourYearsAgo.setMonth(untilDate.getMonth() + 1);
      dataDateRange.value = {
        firstYear: fourYearsAgo.getFullYear(),
        firstMonth: fourYearsAgo.getMonth() + 1,
        latestYear: untilDate.getFullYear(),
        latestMonth: untilDate.getMonth() + 1,
      };
    }

    // Calculate captured and non-captured keywords when working with discovery labels
    if (selectedDiscovery.value.labels) {
      const captured = newValue.filter((kw) => kw.discovery_labels?.length > 0).length;
      const unCaptured = newValue.filter((kw) => !kw.discovery_labels?.length).length;
      tabs.value[0].title = `Captured (${captured})`;
      tabs.value[1].title = `Uncaptured (${unCaptured})`;
    }

    if (oldIds.size !== newIds.size) {
      fetchTimelineStats();
    } else {
      // same size so we need to check if the ids are the same
      for (const id of oldIds) {
        if (!newIds.has(id)) {
          fetchTimelineStats();
          return;
        }
      }
    }
  },
  { immediate: true }
);

watch(
  () => ({ lbls: selectedDiscovery.value.labels, id: selectedDiscovery.value.id }),
  () => discover()
);
const discover = () => {
  store.dispatch("discoverKeywords", {
    ...discoveryFields.value,
    name: "",
    labels: selectedDiscovery.value.labels,
  });
};
const labelingInProgress = ref(false);
const triggerLabeling = async () => {
  if (!selectedDiscovery.value.id) return;

  labelingInProgress.value = true;
  try {
    await store.dispatch("discovery/trigger", selectedDiscovery.value.id);
  } catch (e: any) {
    console.log("Error happened", e);
  }
  labelingInProgress.value = false;
};
const handleDiscoverySave = async (discovery: UserSearch) => {
  const d = await store.dispatch("discovery/createOrUpdateDiscovery", {
    ...selectedDiscovery.value,
    ...discovery,
    name: getDiscoveryName.value,
  });
  if (d) {
    selectedDiscovery.value = d;
    isDraft.value = false;
  }
};
const handleDiscoverySelect = (discovery: Discovery) => {
  selectedDiscovery.value = discovery;
  discoveryFields.value = discovery;
  isDraft.value = false;
};
const handleDiscoveryClear = () => {
  selectedDiscovery.value = {};
  isDraft.value = true;
};
const handleDiscoveryNameChange = async (name: string) => {
  selectedDiscovery.value.name = name;
  if (selectedDiscovery.value.id) {
    const d = await store.dispatch("discovery/updateDiscovery", {
      id: selectedDiscovery.value?.id,
      updates: { name },
      skipNotify: true,
    });
    if (d?.name) {
      recentNameChangeSuccess.value = true;
      setTimeout(() => (recentNameChangeSuccess.value = false), 10000);
    }
  }
};
const handleDiscoveryToggle = async () => {
  if (!discoveryInState.value?.id) return;
  await store.dispatch("discovery/updateDiscovery", {
    id: discoveryInState.value.id,
    updates: { is_active: !discoveryInState?.value.is_active },
  });
};
const handleNameSuggestion = (name: string) => {
  nameSuggestion.value = name;
};
const handleDiscoveryFieldChange = (discovery: Partial<Discovery>) => {
  discoveryFields.value = discovery;
};
const canEditLinkedLabels = computed(() => {
  return selectedDiscovery?.value.id && store.state.user.type?.includes("admin");
});
const labelsById = computed(() => store.state.labels.labelsById ?? {});
const labels = computed(() => Object.values(labelsById.value));
const handleLabelChipClick = (label: Label, e: any) => {
  if ((e.metaKey || e.ctrlKey) && label.id) {
    window.open(`/labels/${label.id}`, "_blank");
  }
};
const handleLinkedLabelAdd = (val: number | number[]) => {
  const labelIds = typeof val === "object" ? val : [val];
  const labelsToAdd = labels.value.filter((lbl) => labelIds.includes(lbl.id));
  selectedDiscovery.value.labels = labelsToAdd;
};
const handleLinkedLabelRemove = (val: Label) => {
  selectedDiscovery.value.labels = selectedDiscovery.value.labels?.filter((lbl) => lbl.id !== val.id) ?? [];
};
const getIconsForLabel = (label: Label): { icon: string; tooltip?: string | undefined }[] | undefined => {
  const icons = [];
  label = labelsById.value?.[label.id];
  if (!label.rules) {
    icons.push({
      icon: "fas fa-warning",
      tooltip: "This label has no rules. Everything in this discovery will receive this label.",
    });
  } else {
    const rule = label.rules[0];
    if (!rule.is_active) {
      icons.push({
        icon: "fas fa-clock",
        tooltip:
          "Auto-labelling is turned off. Enable it in Label Management in order for this Discovery to label correctly.",
      });
    }
    if (isEmpty(label.id, rule.rule)) {
      icons.push({
        icon: "fas fa-warning",
        tooltip: "This label has only an empty rule. Everything in this discovery will receive this label.",
      });
    }
  }
  return icons?.length ? icons : undefined;
};
onMounted(async () => {
  const discoveryId = route.query?.discovery;
  if (discoveryId) {
    const discovery = store.state.discovery.discoveries.find((d) => d.id === parseInt(discoveryId as string));
    if (discovery) handleDiscoverySelect(discovery);
  }
  store.dispatch("selectDynamicFilter");
});

const isEmpty = (label_id: number, rule_details: LabelRuleDetails) => {
  return Object.entries(rule_details).every(([name, rule]) => {
    if (name === "includeAnyLabels") {
      if (rule?.length && JSON.stringify(rule) != JSON.stringify([label_id])) {
        return false;
      }
    } else if (rule?.length) {
      return false;
    }
    return true;
  });
};
</script>
