<template>
  <div class="container">
    <div v-if="!hidden">
      <MaireCheckbox
        :value="toggled"
        @toggle="handleToggle"
        :tooltip-title="tooltipTitle"
        :tooltip-description="tooltipDescription"
        :icon="icon"
      />
    </div>
  </div>
  <!-- <div v-if="!(tooltipTitle || tooltipDescription)" class="container checkboxcell">
    <div v-if="!hidden">
      <v-checkbox
        :prepend-icon="icon"
        density="comfortable"
        v-on:change="handleToggle"
        :model-value="toggled"
        color="purple"
        hide-details
      />
    </div>
  </div>
  <div v-else>
    <div class="container checkboxcell" v-if="!hidden">
      <div>
        <MaireTooltip>
          <template #trigger>
            <div class="checkbox">
              <v-checkbox
                :prepend-icon="icon"
                class="icon-gap"
                density="dense"
                v-on:change="handleToggle"
                :model-value="toggled"
                hide-details
              />
            </div>
          </template>
          <template #content>
            <div style="min-width: 150px">
              <strong v-if="tooltipTitle" style="color: white">{{ tooltipTitle }}</strong>
              <p v-if="tooltipDescription" style="color: white">{{ tooltipDescription }}</p>
            </div>
          </template>
        </MaireTooltip>
      </div>
    </div>
  </div> -->
</template>
<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<script lang="ts" setup>
import { defineProps, computed, PropType } from "vue";
// import MaireTooltip from "@/components/MaireTooltip/MaireTooltip.vue";
import { LabelRule } from "@/store/modules/labels/types";
import MaireCheckbox from "@/components/MaireCheckbox/MaireCheckbox.vue";
const props = defineProps({
  fieldValue: Array as PropType<Array<LabelRule> | boolean>,
  field: Object,
  item: Object,
  icon: { type: String, required: false },
  fn: { type: Function, required: false },
  isToggled: { type: Function, required: false },
  hideCheck: { type: Function, required: false },
  tooltipTitle: { type: String, required: false },
  tooltipDescription: { type: String, required: false },
});

const hidden = props.hideCheck?.(props.fieldValue) ?? false;
const toggled = computed(() => props?.isToggled?.(props.fieldValue) ?? props.fieldValue);

const handleToggle = () => {
  props.fn?.(props.item, !toggled.value);
};
</script>
