<template>
  <ActionBar
    :viewId="viewId"
    :selectedLevel="level"
    :changeLevel="changeLevel"
    :overrideRecommendedLevel="overrideRecommendedLevel"
    :cancelOverride="resetLevel"
    :loading="isLoadingAnalysis"
  />
  <div>
    <div v-show="isLoadingAnalysis" class="wait-animation">
      <wait-animation />
    </div>
    <div
      v-if="!localLoading && !isLoadingAnalysis && !firstLoad && analyses.length === 0 && !fetchingKeywords"
      class="information-paper"
    >
      <div class="information-paper-content">
        <h2>No analysis filters</h2>
        <p>You don't have any analysis filters. Please create a filter to see insights.</p>
      </div>
    </div>
    <div
      v-if="!localLoading && !isLoadingAnalysis && !firstLoad && filterHasNoView"
      class="information-paper"
    >
      <div class="information-paper-content">
        <h2>Analysis doesn't have a view</h2>
        <p>This analysis doesn't have a view. Please create a view for this filter to see insights.</p>
      </div>
    </div>
    <div
      v-show="!isLoadingAnalysis && !filterHasNoView && !firstLoad"
      id="main-analysis-view"
      class="horizontal-padding"
    >
      <div class="drawer" v-show="structureExists">
        <div class="inline-block btn" style="display: inline-block; width: 100%; position: relative">
          <span style="display: inline-block; max-width: 300px">
            <MaireButton
              :compact="true"
              @click="fetchPredictions"
              text="Fetch predictions"
              :disabled="labelStats && Object.keys(labelStats)?.length > 200"
              :isLoading="!!isLoadingPredictions"
              disabledMessage="Please ensure you have only up to 200 labels in your charts. "
            />
          </span>
          <IconToggle
            @toggle="toggleGraphs"
            :state="drawersOpen.includes('graphs') ? 1 : 0"
            :states="graphToggleStates"
          />
        </div>
        <div
          v-if="dataDateRange && !firstLoad"
          class="drawer-content"
          :class="{ active: drawersOpen.includes('graphs') }"
        >
          <v-row>
            <v-col
              :cols="widget.col"
              :md="12"
              :sm="12"
              :lg="6"
              :xl="widget.col"
              :key="widget.name"
              v-for="widget in widgets"
            >
              <MaireChart
                :name="widget.name"
                :id="widget.id"
                :volumes="widget.volumes"
                :dataDateRange="dataDateRange"
                :initialType="widget.type"
                :defaultField="widget.defaultField"
                :params="widget.params"
                :labels="labelsInLevel"
                :loading="!!widget.processing"
                :hideIfEmpty="widget.hideIfEmpty"
                :itemType="widget.itemType"
                :defaultTimeframeText="widget.defaultTimeframeText"
                :onSelect="onLabelSelect"
                :onUnSelect="onLabelUnselect"
                :allowFuture="widget.allowFuture"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-if="structureExists">
        <v-row v-if="!isLoadingAnalysis">
          <v-col cols="12" class="container-margin">
            <div>
              <div class="title-and-search">
                <div class="mb-3 title-text"></div>
                <div class="search_container">
                  <SearchBar />
                </div>
              </div>
              <div>
                <keyword-table
                  :hideFields="['analyses', 'discovery_labels']"
                  :keywords="kws"
                  :totals="kwsSummary"
                  :viewId="viewId"
                  :loading="processing || fetchingKeywords"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else class="center-info">
        <img :src="`${publicPath}images/glass.gif`" alt="Magnifying glass" />
        <p class="xs strong">
          Analysis structure is not yet configured. Assign labels to the levels on the right to see data!
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped>
#main-analysis-view {
  margin-top: 62px;
}
.center-info {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  text-align: center;
  transform: translate(0%, 50%);
  margin-bottom: 300px;
}

.center-info p {
  text-align: center;
}
.information-paper {
  position: relative;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 70%;
  max-width: 600px;
  /* padding: 24px; */
  background-color: white;
  border-radius: 0px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.graphs {
  padding-top: 24px;
  padding-right: 24px;
}
.drawer-content {
  display: none;
  margin-top: 24px;
}
.drawer-content.active {
  display: block;
}
.drawer-chevron {
  position: relative;
  cursor: pointer;
  float: right;
}
.drawer-bar {
  width: 100%;
  cursor: pointer;
}
.container-margin {
  margin-top: 12px;
  margin-bottom: 12px;
}

.title-text {
  margin-bottom: 36px;
  padding-bottom: 20px !important;
  width: 70%;
  display: inline-block;
}
.search_container {
  width: 30%;
  float: right;
  position: relative;
  display: block;
}

.loading {
  filter: blur(4px) brightness(100%);
}
</style>
<script lang="ts" setup>
import KeywordTable from "@/components/KeywordTable/KeywordTable.vue";
import SearchBar from "@/components/DataTable/SearchBar.vue";
import ActionBar from "@/components/ActionBar/ActionBar.vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import MaireChart from "@/components/MaireChart/MaireChart.vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import { recommendedLevelForFilters } from "@/components/helpers/helpers";
import { WidgetType, useStore } from "@/store";
import { Keyword } from "@/store/modules/keywords/types";
import { Ref, computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
import { getEffectiveFilter } from "@/components/MaireChart/helpers";
import IconToggle from "@/components/IconToggle/IconToggle.vue";

onMounted(async () => {
  if (analysisId.value && !viewId.value) {
    if (!analyses.value.length) {
      await store.dispatch("analysis/fetchAnalyses").catch(() => {});
    }
    const defaultView = analysis.value?.views?.[0];
    if (analysis.value && defaultView) {
      router.push({ path: `/analysis/${analysis.value.id}`, query: { view: defaultView.id } });
    }
  }
  setDateRange();
});
const setDateRange = () => {
  const dt = analysisSummary.value?.until_date;
  if (dt) {
    const untilDate = new Date(dt);
    const fourYearsAgo = new Date(dt);
    fourYearsAgo.setFullYear(untilDate.getFullYear() - 4);
    fourYearsAgo.setMonth(untilDate.getMonth() + 1);
    dataDateRange.value = {
      firstYear: fourYearsAgo.getFullYear(),
      firstMonth: fourYearsAgo.getMonth() + 1,
      latestYear: untilDate.getFullYear(),
      latestMonth: untilDate.getMonth() + 1,
    };
  } else {
    dataDateRange.value = undefined;
  }
};
const debounce = (func: Function, wait: number) => {
  let timeout: number | undefined;

  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => func(...args), wait);
  };
};
const store = useStore();
const route = useRoute();
const router = useRouter();
const kws: Ref<Keyword[]> = ref([]);
const kwsSummary: Ref<Record<string, number>> = ref({});
const processing: Ref<boolean> = ref(true);
const overrideRecommendedLevel = ref(false);
const filterHasNoView: Ref<boolean> = ref(false);
const localLoading: Ref<boolean> = ref(true);
const level = ref(1);
const recommendedLevel: Ref<number | undefined> = ref(1);
const currentLevel = computed(() => store.state.analysis.views?.[viewId.value]?.currentLevel);
const fetchingKeywords = ref(false);

const drawersOpen = ref(["labelFilters", "graphs"] as string[]);

const loadingLabelVolumes = computed(
  () => filterId?.value && store.state.loading.loadingLabelVolumes.includes(filterId.value)
);
const isLoadingPredictions = computed(
  () => filterId?.value && store.state.loading.loadingPredictions.includes(filterId.value)
);

const filters = computed(() => store.state.analysis.currentFilters);
const analyses = computed(() => store.state.analysis.analyses);
const analysis = computed(() => store.state.analysis.analyses.find((af) => af.id === analysisId.value));
const analysisSummary = computed(() => analysis.value?.summary);
const viewId = computed(() => (route.query.view ? parseInt(route.query.view as string) : 0));
const analysisId = computed(() => parseInt(route.params.id as string));
const labelsInParams = computed(() =>
  route.query.labels
    ? new Set((route.query.labels as string).split(",").map((label) => decodeURIComponent(label)))
    : ""
);
const view = computed(() => {
  return viewId.value ? store.state.analysis.views?.[viewId.value] : undefined;
});
const publicPath = process.env.BASE_URL;
const structureExists = computed(() => {
  return !!store.state.labels.viewLabels?.[viewId.value]?.length;
});

const isLoadingAnalysis = computed(
  () => view.value && store.state.loading.loadingAnalysis?.includes(view.value.id)
);
const firstLoad = ref(true);

const labelStats = ref(undefined);

const fetchKeywords = async () => {
  processLabelStats();
  fetchingKeywords.value = true;
  await store
    .dispatch("analysis/fetchMatchingKeywords", { labels: labelsInLevel.value })
    .then((r: any) => {
      if (!r) return;
      kws.value = r.kws;
      kwsSummary.value = r.summary;
      processing.value = false;
      fetchingKeywords.value = false;
      localLoading.value = false;
    })
    .catch(() => {});
};

const refreshData = () => {
  const newRecommendedLevel = filters.value.labelFilters.include.labels?.filter((lbl) => lbl.checked)?.length
    ? recommendedLevelForFilters(filters.value, store.state.labels.viewLabels?.[viewId.value])
    : 1;
  if (newRecommendedLevel !== recommendedLevel.value) {
    recommendedLevel.value = newRecommendedLevel;
  }
  if (!isLoadingAnalysis.value && !firstLoad.value) {
    debouncedFetchKeywords();
  }
};
const labelsInLevel = computed(() => {
  return (
    store.state.labels.viewLabels?.[viewId.value]
      ?.filter((label) => label.level === level.value)
      .map((lbl) => lbl.text) ?? []
  );
});
const triggerRecalculation = computed(() => store.state.keywords.triggerRecalculation);
watch(
  () =>
    JSON.stringify({
      filters: getEffectiveFilter(filters.value),
      labels: labelsInLevel.value,
    }),
  refreshData
);
watch(triggerRecalculation, () => {
  if (triggerRecalculation.value) {
    refreshData();
  }
  store.commit("keywords/setTriggerRecalculation", false);
});
watch(analysisSummary, () => {
  setDateRange();
});

const dataDateRange: Ref<object | undefined> = ref(undefined);
const widgets = computed(() => {
  return [
    {
      id: 1,
      type: WidgetType.BarChart,
      name: `Volume by category label`,
      defaultTimeframeText: "Last 12 months",
      volumes: labelStats.value,
      processing: loadingLabelVolumes.value,
      defaultField: "volume",
      col: 4,
      itemType: "category",
      allowFuture: true,
    },
    {
      id: 2,
      type: WidgetType.BarChart,
      name: `Growth by category label`,
      defaultTimeframeText: "Last 12 months",
      volumes: labelStats.value,
      defaultField: "growth",
      processing: loadingLabelVolumes.value,
      col: 4,
      itemType: "category",
      allowFuture: true,
    },
    {
      id: 6,
      type: WidgetType.TimelineChart,
      name: "Label timeline",
      volumes: labelStats.value,
      defaultField: "",
      processing: loadingLabelVolumes.value,
      col: 4,
      allowFuture: true,
    },
    {
      id: 3,
      type: WidgetType.BarChart,
      name: "Trending by keyword",
      defaultTimeframeText: "Latest",
      defaultField: "trending",
      col: 4,
      itemType: "keyword",
    },
    {
      id: 6,
      type: WidgetType.BarChart,
      name: "Volume by keyword",
      defaultTimeframeText: "Last 12 months",
      defaultField: "volume",
      col: 4,
      itemType: "keyword",
    },
    {
      id: 4,
      type: WidgetType.BarChart,
      name: "Growth by keyword",
      defaultTimeframeText: "Last 12 months",
      defaultField: "growth",
      col: 4,
      itemType: "keyword",
    },
    {
      id: 4,
      type: WidgetType.BarChart,
      name: "Absolute growth by keyword",
      defaultTimeframeText: "Last 12 months",
      defaultField: "absoluteGrowth",
      col: 4,
      itemType: "keyword",
    },
    {
      id: 5,
      type: WidgetType.BarChart,
      defaultField: "volume",
      name: "New keywords",
      params: { include_only_new: true },
      col: 6,
      hideIfEmpty: true,
      itemType: "keyword",
    },
  ];
});
const processLabelStats = () => {
  if (labelsInLevel.value?.length === 0) {
    processing.value = false;
    return;
  }
  store
    .dispatch("analysis/fetchAnalysisLabelStats", {
      analysisId: analysisId.value,
      labels: labelsInLevel.value,
    })
    .then((data) => {
      labelStats.value = data;
      processing.value = false;
    })
    .finally(() => {
      clearPredictionTimeframe();
      localLoading.value = false;
    });
};
const debouncedFetchKeywords = debounce(fetchKeywords, 200);

const filterId = ref(route.params.id ? parseInt(route.params.id as string) : undefined);
watch(recommendedLevel, () => {
  if (!overrideRecommendedLevel.value && recommendedLevel.value && recommendedLevel.value !== level.value) {
    level.value = recommendedLevel.value;
    if (currentLevel.value !== level.value) {
      store.dispatch("analysis/switchAnalysisFilterLevel", {
        filterId: analysisId.value,
        viewId: viewId.value,
        level: level.value,
      });
    }
  }
});

const clearPredictionTimeframe = () => {
  dataDateRange.value = {
    ...dataDateRange.value,
    predictionYear: undefined,
    predictionMonth: undefined,
  };
};

const changeLevel = async (newLevel: number) => {
  overrideRecommendedLevel.value = true;
  clearPredictionTimeframe();
  if (level.value !== newLevel) {
    level.value = newLevel;
    processing.value = true;
    setTimeout(() => {
      store.dispatch("analysis/switchAnalysisFilterLevel", {
        filterId: analysisId.value,
        viewId: viewId.value,
        level: level.value,
      });
    }, 0);
  }
};
const resetLevel = async () => {
  overrideRecommendedLevel.value = false;
  level.value = recommendedLevel.value ?? 1;
  processing.value = true;
  setTimeout(() => {
    store.dispatch("analysis/switchAnalysisFilterLevel", {
      filterId: analysisId.value,
      viewId: viewId.value,
      level: level.value,
    });
  }, 0);
};
const fetchPredictions = () => {
  store
    .dispatch("analysis/fetchPredictions", {
      analysisId: analysisId.value,
      series: labelStats.value,
    })
    .then((data) => {
      labelStats.value = data;
      dataDateRange.value = {
        ...dataDateRange.value,
        // @ts-ignore
        predictionYear: Math.max(...Object.keys(Object.values(data)?.[0]).map((year) => parseInt(year))),
        // @ts-ignore
        predictionMonth: (dataDateRange.value.latestMonth + 18) % 12,
      };
    });
};
const onLabelSelect = (label: string) => {
  store.dispatch("analysis/addLabelFilter", { type: "include", label });
};
const onLabelUnselect = (label: string) => {
  store.dispatch("analysis/removeLabelFilter", label);
};
const graphToggleStates = ref({
  0: { icon: "far fa-eye", text: "Show graphs" },
  1: { icon: "far fa-eye-slash", text: "Hide graphs" },
});
const toggleGraphs = () => {
  if (drawersOpen.value.includes("graphs")) {
    drawersOpen.value = drawersOpen.value.filter((v) => v !== "graphs");
  } else {
    drawersOpen.value.push("graphs");
  }
};
watch(
  viewId,
  async (curr: number | undefined, old: number | undefined) => {
    if (viewId.value && curr !== old) {
      filterHasNoView.value = false;
      firstLoad.value = true;
      labelStats.value = undefined;
      kws.value = [];
      kwsSummary.value = {};
      level.value = 1;
      await store.dispatch("selectView", {
        filterId: analysisId.value,
        viewId: viewId.value,
        labels: labelsInParams.value,
      });
      firstLoad.value = false;
      refreshData();
    } else {
      // Filter doesn't have a view. Show information for the user guiding to create a view
      filterHasNoView.value = true;
    }
  },
  { immediate: true }
);
</script>
