<template>
  <span v-if="!trends" class="error"> Unfortunately search trends are not available for this keyword. </span>
  <div>
    <div v-if="loading" class="waiting_container">
      <WaitAnimation message="Maire is predicting the future" />
    </div>
    <apexchart v-if="!loading && trends" :loading="true" width="100%" :options="options" :series="trends">
    </apexchart>
  </div>
</template>
<style scoped>
.waiting_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  padding: 13%;
}
span {
  background-color: rgb(255, 176, 176);
  margin-top: 70px;
  padding: 10px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

import WaitAnimation from "../WaitAnimation/WaitAnimation.vue";
import { numberFormatter } from "../helpers/helpers";
import { useStore } from "@/store";

export default defineComponent({
  name: "LineChart",
  data: () => ({
    direction: "horizontal",
    margin: {
      left: 0,
      top: 20,
      right: 20,
      bottom: 0,
    },
    publicPath: process.env.BASE_URL,
  }),
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    loading() {
      return this.store.state.loading.loadingPrediction;
    },
    keyword() {
      return this.store.state.keywords.keywordWithDetails;
    },
    trends() {
      const trendData = this.keyword?.popularity_trends?.map((x: any) => ({
        x: x["date_from"],
        y: x["value"] || 0,
      }));
      const hmData = this.keyword?.historical_metrics?.map((x: any) => ({
        x: x["date_from"],
        y: x["monthly_searches"],
      }));
      const predictionData = this.keyword?.prediction?.forecast_with_confidence?.map((x: any) => ({
        x: x["date_from"],
        y: x["monthly_searches"],
      }));
      const lowerConfidence = this.keyword?.prediction?.forecast_with_confidence?.map((x: any) => ({
        x: x["date_from"],
        y: x.is_forecast ? x["lower_confidence"] : null,
      }));
      const upperConfidence = this.keyword?.prediction?.forecast_with_confidence?.map((x: any) => ({
        x: x["date_from"],
        y: x.is_forecast ? x["upper_confidence"] : null,
      }));
      const series: any[] = [];
      if (!predictionData && hmData) {
        series.push({
          name: "Search volume",
          type: "bar",
          data: hmData,
        });
      }
      if (predictionData) {
        series.push({
          name: "80% confidence - High",
          type: "area",
          data: upperConfidence,
        });
        series.push({
          name: "80% confidence - Low",
          type: "area",
          data: lowerConfidence,
        });
        series.push({
          name: "Volumes",
          type: "line",
          data: predictionData,
        });
      }
      if (trendData && trendData.length > 0) {
        series.push({
          name: "Trend score",
          data: trendData,
          type: "area",
        });
      }
      return series;
    },
    options() {
      const predictionData = this.keyword?.prediction?.forecast_with_confidence?.filter(
        (x: any) => x.is_forecast
      );
      const firstDayOfForecast = predictionData
        ? new Date(predictionData.slice(0)?.[0]?.date_from)?.getTime()
        : undefined;
      const lastDayOfForecast = predictionData
        ? new Date(predictionData.slice(-1)?.[0]?.date_from)?.getTime()
        : undefined;

      return {
        chart: {
          id: "TrendsAndsSearchVolumes",
          stacked: false,
          toolbar: {
            show: true,
            tools: {
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
              customIcons: [],
            },
          },
        },
        title: {
          text: this.keyword?.keyword,
          offsetY: 5,
          align: "center",
          style: { color: "#8200FF", fontFamily: "Nunito Sans" },
        },
        colors: ["#8200FF", "#8200FF"],
        fill: {
          colors: ["#8200FF", "#FFFFFF", "#FFFFFF", "rgba(171, 235, 234, 1)"],
          opacity: [0.1, 1.0, 1.0, 1.0],
          type: "solid",
        },
        xaxis: {
          type: "datetime",
        },
        annotations: {
          xaxis: lastDayOfForecast
            ? [
                {
                  x: firstDayOfForecast,
                  x2: lastDayOfForecast,
                  fillColor: "#8200FF",
                  borderColor: "#8200FF",
                  opacity: 0.05, // Adjust the opacity to your preference
                  label: { text: "Forecasting period", style: { color: "#8200FF" } },
                },
              ]
            : undefined,
        },
        yaxis: this.trends.map((x, index) => ({
          show: index === 0 || x.name.includes("Trend score"),
          formatter: function (val: any) {
            return val ? numberFormatter(val, 2) : null;
          },
          seriesName: x.name.includes("Trend score") ? "Trend score" : "Volumes",
          title: {
            text: index === 0 ? "Search volume" : x.name.includes("Trend score"),
          },
          opposite: x.name.includes("Trend score"),
          labels: {
            formatter: function (val: any) {
              return val ? numberFormatter(val, 2) : val;
            },
          },
        })),
        grid: {
          strokeDashArray: 0,
          position: "front",
        },
        stroke: {
          show: true,
          width: [1, 1, 4, 0],
        },
        tooltip: {
          shared: true,
          followCursor: true,
          x: {
            formatter: function (val: any) {
              const dt = new Date(val);
              return `${dt.getFullYear()} / ${dt.getMonth() + 1} / ${dt.getDate()}`;
            },
          },
          y: {
            formatter: function (val: any) {
              return val ? numberFormatter(val, 2) : null;
            },
          },
        },
      };
    },
    hm() {
      return this.keyword?.historical_metrics;
    },
  },
  components: { WaitAnimation },
});
</script>
