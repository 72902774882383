<template>
  <div class="title">
    <v-icon @click="toggleMinimize">
      {{ minimized ? "far fa-caret-down" : "far fa-caret-up" }}
    </v-icon>
    <v-icon v-if="editingAllowed" @click="toggleEditing">fas fa-pencil </v-icon>
    <strong class="xs pa-2">Source labels</strong>
  </div>
  <p class="text xxs" v-show="!minimized">
    These labels will define the set of keywords that the analysis will be built upon. All other labels that
    you choose to include in your analysis will only be shown in relation to the source labels. You can change
    source labels at any point.
  </p>
  <div class="chips" v-show="!minimized">
    <MaireChipsInput
      label="Add label(s)"
      field="text"
      :limit="500"
      :itemList="labels"
      :items="localSourceLabels"
      :selected="localSourceLabels.map((lbl) => lbl.id)"
      :hideInput="!editingAllowed || !editSourceLabels"
      :closable="editingAllowed && editSourceLabels"
      hide-selected
      :link-fn="(label: Label) => `/labels/${label.id}`"
      @add="handleSourceLabelAdd"
      @remove="handleSourceLabelRemove"
    />
  </div>
  <div class="actionbar-bottom-buttons" v-show="editSourceLabels && !minimized">
    <MaireButton
      text="Save"
      class="save-analysis-button"
      @click="handleSourceLabelSave"
      variant="contained"
      comfortable
    />
  </div>
</template>
<style scoped>
.title {
  display: flex;
  align-items: center;
  gap: 12px;
}
</style>
<script lang="ts" setup>
import { useStore } from "@/store";
import { Label } from "@/store/modules/labels/types";
import { PropType, ref, Ref, defineProps, watch, defineEmits, computed } from "vue";
import MaireChipsInput from "../MaireChipsInput/MaireChipsInput.vue";
import MaireButton from "../ButtonBlock/MaireButton.vue";
const store = useStore();
const props = defineProps({
  labels: {
    type: Array as PropType<Label[]>,
    required: true,
  },
  sourceLabels: {
    type: Array as PropType<Label[]>,
    required: true,
  },
  editingAllowed: { type: Boolean, required: false, default: false },
});

const emit = defineEmits(["save"]);
const editSourceLabels = ref(false);
const minimized = ref(true);
const localSourceLabels: Ref<Label[]> = ref([]);
const labelsById = computed(() => {
  return store.state.labels.labelsById;
});
const toggleEditing = () => {
  editSourceLabels.value = !editSourceLabels.value;
  if (editSourceLabels.value) {
    minimized.value = false;
  }
};
const toggleMinimize = () => {
  minimized.value = !minimized.value;
  if (minimized.value) editSourceLabels.value = false;
};
const handleSourceLabelAdd = (labelId: number) => {
  if (!localSourceLabels.value.map((v) => v.id).includes(labelId)) {
    const label = labelsById.value?.[labelId];
    if (label) localSourceLabels.value.push(label);
  }
};
const handleSourceLabelRemove = (label: Label) => {
  localSourceLabels.value = localSourceLabels.value.filter((v) => v.id !== label.id);
};
const handleSourceLabelSave = () => {
  emit("save", localSourceLabels.value);
  editSourceLabels.value = false;
};

watch(
  () => props.sourceLabels,
  () => {
    localSourceLabels.value = [...(props.sourceLabels ?? [])];
    if (props.sourceLabels?.length === 0) {
      editSourceLabels.value = true;
    } else {
      editSourceLabels.value = false;
    }
  },
  { immediate: true }
);
</script>
