<template>
  <div class="label_cell inputs">
    <div class="category-input-field inline" :key="chip" v-for="chip in chips.slice(0, noOfVisible)">
      <MaireChip
        v-if="chip"
        :density="density"
        :label="getLabelText(chip)"
        :color="color"
        :closable="false"
      />
    </div>
    <div v-if="chips.length > noOfVisible" style="display: inline-block; margin-left: 12px">
      <MaireTooltip :follow="false">
        <template #trigger>
          <span class="xs"> + {{ chips.length - noOfVisible }} </span>
        </template>
        <template #content>
          <div :key="chip" v-for="chip in chips.slice(noOfVisible, chips.length)">
            <MaireChip
              v-if="chip"
              :density="density"
              :label="getLabelText(chip)"
              :color="color"
              :closable="false"
            />
          </div>
        </template>
      </MaireTooltip>
    </div>
  </div>
</template>
<style scoped>
.label span {
  max-width: 150px;
  font-size: 12px;
}
.label {
  cursor: pointer;
}
.label {
  margin: 2px;
}
</style>

<script lang="ts" setup>
import { defineProps, computed } from "vue";
import MaireTooltip from "@/components/MaireTooltip/MaireTooltip.vue";
import MaireChip from "@/components/MaireChip/MaireChip.vue";
const chips = computed(() => {
  return ((typeof props.fieldValue === "object" ? props.fieldValue : [props.fieldValue]) ?? []).filter(Boolean);
});

const getLabelText = (chip: any) => {
  // @ts-ignore
  return props.itemKey && typeof chip === "object" ? chip?.[props.itemKey] : chip;
};
const props = defineProps({
  fieldValue: String,
  field: Object,
  item: Object,
  itemKey: { type: String, required: false },
  color: { type: String, required: false },
  noOfVisible: { type: Number, required: false, default: 3 },
  density: { type: String, required: false },
});
</script>
