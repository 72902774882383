<template>
  <div class="label_cell inputs">
    <v-icon
      class="preventClick plusIcon centeredIcon"
      v-if="!creatingNew && editable"
      @click="creatingNew = true"
    >
      fas fa-plus
    </v-icon>
    <div
      v-for="label in parsedLabels.slice(0, noOfVisible)"
      :key="label.text"
      class="category-input-field inline"
    >
      <LabelCellLabel
        :label="label"
        :density="density"
        :closable="editable"
        @clickClose="(lbl: ViewLabel) => openModal(lbl)"
        @clickLabel="(lbl, e) => searchLabel(lbl ?? lbl, e)"
      />
    </div>
    <div v-if="parsedLabels.length > noOfVisible" style="display: inline-block; margin-left: 12px">
      <MaireTooltip :follow="false">
        <template #trigger>
          <span class="xs"> + {{ parsedLabels.length - noOfVisible }} </span>
        </template>
        <template #content>
          <div
            v-for="label in parsedLabels.slice(noOfVisible, parsedLabels.length)"
            :key="label.text"
            class="category-input-field inline"
          >
            <LabelCellLabel
              :label="label"
              :closable="editable"
              :density="density"
              @clickClose="(lbl: ViewLabel) => openModal(lbl)"
              @clickLabel="(lbl, e) => searchLabel(lbl, e)"
            />
          </div>
        </template>
      </MaireTooltip>
    </div>
    <v-autocomplete
      v-if="creatingNew"
      variant="outlined"
      v-model="newLabel"
      :items="analysisLabels"
      item-value="text"
      item-title="text"
      autofocus
      class="category-input-field preventClick labelInput"
      density="compact"
      :hide-details="true"
      @update:modelValue="saveLabel"
      @keydown.enter="(e: any) => saveLabel(e.target?.value)"
    />
  </div>
  <MaireDialog
    :fn="removeLabelFromKeyword"
    :title="`Remove ${labelToDelete?.text}`"
    :description="`Do you want to remove this label from selected keywords, or just remove it from the keyword: ${item?.keyword}? Note that removing a label from a keyword applies across all analyses where it's used.`"
    :open="modalOpen"
    :onClose="closeModal"
    hidebuttons
  >
    <v-btn @click="modalOpen = false" class="plain-btn inline"> Cancel </v-btn>
    <v-btn v-if="selectedItems?.length" @click="removeLabelFromSelected" class="plain-btn inline danger">
      Remove from selected</v-btn
    >
    <v-btn @click="removeLabelFromKeyword" class="plain-btn inline inverse"> Remove from this keyword</v-btn>
  </MaireDialog>
</template>
<style scoped>
.plusIcon {
  color: rgba(var(--v-theme-mairePurple)) !important;
  border-radius: 25px;
  margin: 5px;
  padding: 0px;
  text-align: center;
  outline: 2px solid rgba(var(--v-theme-mairePurple));
}
.labelInput {
  margin-top: 10px;
}

.label span {
  max-width: 50px;
}
.label {
  cursor: pointer;
  position: relative;
  margin: 2px;
}
.danger {
  background-color: red !important;
  color: white !important;
  border-style: none;
}
.inverse {
  background-color: rgba(var(--v-theme-mairePurpleSecondary)) !important;
}

.danger:hover {
  border-style: none;
  color: white !important;
}

p {
  color: white;
  font-size: 12px;
}
</style>

<script lang="ts" setup>
import { PropType, ref, computed, defineProps } from "vue";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import { ViewLabel } from "@/store/modules/labels/types";
import { Keyword } from "@/store/modules/keywords/types";
import { useStore } from "@/store";
import MaireTooltip from "@/components/MaireTooltip/MaireTooltip.vue";
import LabelCellLabel from "./LabelCellLabel.vue";

const store = useStore();
const modalOpen = ref(false);
const noOfVisible = ref(3);
const labelToDelete = ref(undefined as ViewLabel | undefined);
const creatingNew = ref(false);
const newLabel = ref("");

const props = defineProps({
  fieldValue: Array as PropType<ViewLabel[]>,
  field: Object,
  item: Object,
  viewId: { type: Number, required: false },
  selectedItems: Array as PropType<Keyword[]>,
  editable: { type: Boolean, required: false, default: true },
  density: { type: String, required: false },
});

const currentAnalysis = computed(() => {
  return store.state.analysis.currentAnalysis;
});
const labels = computed(() => {
  return store.state.labels.labelsById;
});
const parsedLabels = computed(() => {
  const lbls = props.fieldValue?.map((lbl: ViewLabel | number) => {
    if (typeof lbl === "number") {
      return labels.value?.[lbl as unknown as number];
    } else {
      return lbl;
    }
  });
  // @ts-ignore
  return lbls?.filter((lbl) => lbl?.text) ?? [];
});
const analysisLabels = computed(() => {
  if (!currentAnalysis.value?.id) {
    return Object.values(store.state.labels.labels) ?? [];
  }
  return store.state.labels.analysisLabels?.[currentAnalysis.value?.id] ?? [];
});
const saveLabel = (label?: string) => {
  creatingNew.value = false;
  const labelToCreate = label || newLabel.value;
  newLabel.value = "";
  if (!labelToCreate || !props.item?.id) {
    return;
  }
  store.dispatch("keywords/addLabelToKeywords", {
    label: labelToCreate,
    kwIds: [props.item?.id],
    filter: currentAnalysis.value,
    viewId: props.viewId,
  });
};
const closeModal = () => {
  modalOpen.value = false;
};
const openModal = (label: ViewLabel) => {
  modalOpen.value = true;
  labelToDelete.value = label;
};
const removeLabelFromKeyword = () => {
  closeModal();
  store.dispatch("keywords/removeLabelFromKeywords", {
    labels: [labelToDelete.value],
    kwIds: [props.item?.id],
  });
};
const removeLabelFromSelected = () => {
  closeModal();
  if (!props.selectedItems?.length || !labelToDelete.value) return;

  store.dispatch("keywords/removeLabelFromKeywords", {
    labels: [labelToDelete.value],
    kwIds: props.selectedItems.map((kw) => kw.id),
  });
};
const searchLabel = (label: ViewLabel, e: any) => {
  if (e.target?.className?.includes("fa-times-circle")) {
    return;
  }
  // if metakey is clicked, open label's page. If not, add to filters
  if ((e.metaKey || e.ctrlKey) && label.id) {
    window.open(`/labels/${label.id}`, "_blank");
  } else {
    store.dispatch("analysis/addLabelFilter", { label: label.text });
  }
};
</script>
