<template>
  <div class="chat-view-container">
    <div class="chat-view">
      <div class="messages-view" ref="chatContainer">
        <div
          v-for="(message, index) in conversationWithInitialMessages"
          :key="index"
          :class="['message', message.role]"
        >
          <div class="message-container">
            <div v-if="message.role === 'assistant'" class="avatar-container">
              <img :src="`${publicPath}images/maire_avatar.png`" alt="Avatar" class="avatar" />
            </div>
            <div class="message-content">
              <div v-dompurify-html="markdownToHTML(message.content)"></div>
            </div>
          </div>
        </div>
        <div v-if="maireIsTyping" class="typing-indicator message assistant">
          <img class="avatar" :src="`${publicPath}images/maire_avatar.png`" />
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div></div>
      <div class="input-container">
        <input
          type="text"
          v-model="userMessage"
          @keyup.enter="sendMessage"
          placeholder="Type your message here"
        />

        <button @click="sendMessage"><i class="fas fa-paper-plane"></i></button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.avatar-container {
  display: flex;
  align-items: flex-start;
  margin-right: 0px;
}

.message-container {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-content p {
  font-family: "Nunito Sans", sans-serif;
}

.typing-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.typing-indicator div:nth-child(1) {
  animation-delay: 0s;
}

.typing-indicator div:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator div:nth-child(3) {
  animation-delay: 0.4s;
}

.typing-indicator div {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
  margin: 0 3px;
  animation: typing 1.5s ease-in-out infinite;
}

@keyframes typing {
  0% {
    transform: scale(1);
  }

  33% {
    transform: scale(1.5);
  }

  66% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

code {
  background: rgba(149, 149, 149, 0.8);
  padding: 10px;
  font-size: 12px;
}

.chat-view-container {
  position: relative;
  display: block;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  padding: 0 0%;
}

.chat-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  white-space: pre-wrap;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(130, 0, 255, 0.1);
}

.messages-view {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  line-height: 1.5;
  max-width: 80%;
}

.user {
  align-self: flex-end;
  background-color: #f8d2ff;
  margin-right: 30px;
}

.assistant {
  align-self: flex-start;
  background-color: #b500ff;
  color: white;
}

.input-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

input[type="text"] {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 25px;
  background-color: #eee;
  font-size: 0.8rem;
  line-height: 1.5;
  outline: none;
}

button {
  background-color: #b500ff;
  color: #fff;
  border: none;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

button:hover {
  background-color: #8200ff;
}

.fa-paper-plane {
  font-size: 1rem;
}
</style>

<script lang="ts">
// @ is an alias to /src

import { marked } from "marked";

// import "highlight.js/styles/vs2015.css";
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
export default defineComponent({
  name: "ChatView",
  data() {
    return {
      userMessage: "",
      processingOutput: "",
      processingStatus: "",
      messages: [] as string[],
      messageText: "",
      socket: null as WebSocket | null,
    };
  },
  setup() {
    const store = useStore();
    const conversationWithInitialMessages = computed(() => {
      const initialMessages = [
        {
          role: "assistant",
          content: "Hello, I'm Maire. 😊",
        },
        {
          role: "assistant",
          content:
            "You can ask me for example to give tips on your article, correct grammar or spelling mistakes, or even to translate your text into another language. 💭\n\nWhat do you need?  ",
        },
      ];
      return [...initialMessages, ...store.state.conversation];
    });
    const conversation = computed(() => {
      return store.state.conversation;
    });
    const maireIsTyping = computed(() => {
      return store.state.loading.maireIsTyping;
    });
    return { store, conversationWithInitialMessages, conversation, maireIsTyping };
  },
  watch: {
    conversation() {
      this.$nextTick(() => {
        const chatView = this.$refs.chatContainer as HTMLDivElement;
        chatView.scrollTop = chatView.scrollHeight;
      });
    },
  },
  methods: {
    sendMessage() {
      // Add user message to chat history
      if (!this.userMessage) return;
      const newMessage = {
        role: "user",
        content: this.userMessage,
      };
      this.store.dispatch("sendMessage", [...this.conversation, newMessage]);
      this.userMessage = "";
    },
    scrollToBottom() {
      const container = this.$refs.chatContainer as HTMLDivElement;
      container.scrollTop = container.scrollHeight;
    },
    formatMessage(message: { content: string }) {
      // Formats the message content to display code blocks nicely
      let formattedMessage = message.content;
      const codeRegex = /`([^`]+)`/g;
      formattedMessage = formattedMessage.replace(codeRegex, "<code>$1</code>");
      return formattedMessage;
    },
    markdownToHTML(message: string) {
      return marked(message, {});
    },
    sendSocketMessage() {
      if (this.socket?.readyState !== WebSocket.OPEN) {
        console.error("WebSocket not open");
        return;
      }
      this.socket?.send("random message   ");
      this.messageText = "";
    },
  },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
  },
});
</script>
