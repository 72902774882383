<template>
  <div id="action-bar" :class="{ draft: isDraft }">
    <div class="discovery-name-field">
      <div class="discovery-activity-toggle" v-if="discovery?.id">
        <MaireCheckbox
          :value="discovery?.is_active"
          @toggle="handleToggle"
          tooltipTitle="Automatic discovery"
          tooltipDescription="When selected, the discovery will periodically be run automatically and checked for matching labels."
          icon="far fa-clock"
          classes="bg-maire-purple"
        />
      </div>
      <v-text-field
        v-if="isAdmin"
        style="width: 100%"
        @update:modelValue="handleTitleChange"
        hide-details
        class="title s strong"
        variant="default"
        v-model="name"
      >
        <template #append-inner v-if="recentSuccess">
          <Transition name="fade">
            <div class="success-icon">
              <v-icon v-if="recentSuccess">fas fa-check-circle</v-icon>
            </div>
          </Transition>
        </template>
      </v-text-field>
      <div v-else class="name">
        <p class="s strong">{{ name }}</p>
      </div>
    </div>
    <div @click="emit('close-discovery')" class="close-btn" v-if="!isDraft">
      Close
      <v-icon>far fa-xmark</v-icon>
    </div>
  </div>
</template>

<style scoped>
.discovery-activity-toggle {
  margin-right: 24px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.discovery-name-field {
  position: relative;
  display: flex;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  margin-top: 0px;
  margin-right: 24px;
  margin-left: 24px;
}
.success-icon {
  /* position: absolute; */
  left: 12px;
  margin-left: 12px;
  margin-top: 4px;
  color: lightgreen;
}
.title {
  color: white;
  margin: auto;
  position: relative;
  padding: 0px 0px 0px 0px;
  display: inline-block;
}
.close-btn {
  display: flex;
  align-items: center;
  color: white;
  border: 1px solid white;
  border-radius: 20px;
  padding: 4px 16px;
  margin-right: 48px;
  cursor: pointer;
}
.close-btn i {
  margin-left: 8px;
}

#action-bar {
  position: fixed;
  display: flex;
  z-index: 3;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  height: 52px;
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 1);
}
.name {
  color: white;
  margin: auto;
  width: 50%;
  position: absolute;
  padding: 10px 10px 10px 100px;
  display: inline-block;
}

.name p {
  color: white;
}
#action-bar.draft {
  background-color: rgba(var(--v-theme-maireGrey), 1) !important;
}
</style>
<script lang="ts" setup>
import { useStore } from "@/store";
import { defineProps, watch, ref, Ref, defineEmits, computed, PropType } from "vue";
import MaireCheckbox from "../MaireCheckbox/MaireCheckbox.vue";
import { Discovery } from "@/store/modules/discovery/types";

const props = defineProps({
  title: { type: String, required: true },
  isDraft: { type: Boolean, required: false, default: true },
  recentSuccess: { type: Boolean, required: false, default: false },
  discovery: { type: Object as PropType<Partial<Discovery>>, required: false },
});

const name = ref("");
watch(
  () => props.title,
  () => {
    if (props.title !== name.value) {
      name.value = props.title;
    }
  },
  { immediate: true }
);
const store = useStore();
const isAdmin = computed(() => {
  return store.state.user?.type === "admin";
});
const emit = defineEmits(["titleChange", "close-discovery"]);
const timerId: Ref<any | null> = ref(null);
const handleTitleChange = (data: string) => {
  if (timerId.value) clearTimeout(timerId.value);
  timerId.value = setTimeout(() => emit("titleChange", data), 500);
};
const handleToggle = () => emit("toggle");
</script>
