<template>
  <MaireDialog
    :onClose="() => (deletionModalOpen = false)"
    :open="deletionModalOpen"
    :description="description"
    confirmText="Delete"
    :fn="removeChip"
  />
  <div class="rule-chips">
    <MaireChip
      overrideClose
      :key="item?.[field]"
      v-for="item in itemObjects.slice(0, limit)"
      :closable="closable"
      :density="density"
      :label="item?.[field]"
      :icons="props.prependFn?.(item)"
      :color="color"
      @select="(label: string, e: any) => handleSelect(item, e)"
      @close="(e) => handleClose(item, e)"
    />
  </div>
</template>
<style scoped>
.rule-chips {
  margin-top: 8px;
}
</style>
<script lang="ts" setup>
import { Label } from "@/store/modules/labels/types";
import { defineProps, defineEmits, ref, computed, Ref, PropType } from "vue";
import MaireChip from "../MaireChip/MaireChip.vue";
import MaireDialog from "../MaireDialog/MaireDialog.vue";

interface Item {
  [key: string]: string;
}
const emit = defineEmits(["close", "click"]);
const props = defineProps({
  field: { type: String, required: false, default: "value" },
  items: { type: Array as () => (Item | string | Label)[], required: true, default: () => [] },
  color: { type: String, required: false },
  requireConfirmation: { type: Boolean, required: false, default: () => false },
  prependFn: {
    // the input will be the item itself
    // eslint-disable-next-line no-unused-vars
    type: Function as PropType<(input: any) => { icon: string; tooltip?: string }[] | undefined>,
    required: false,
  },
  closable: { type: Boolean, required: false, default: () => true },
  density: { type: String, required: false, default: "comfortable" },
  limit: { type: Number, required: false },
  description: { type: String, required: false, default: "Are you sure?" },
});

const deletionModalOpen = ref(false);
const itemToDelete: Ref<Item | String | undefined> = ref();
const itemObjects = computed(() => {
  if (!props.items?.length) return [];
  const stringItems = typeof props.items?.[0] === "string";
  return (stringItems ? props.items.map((value) => ({ [props.field]: value })) : props.items) as Item[];
});
const handleClose = (item: Item | String, e: any) => {
  if (props.requireConfirmation) {
    deletionModalOpen.value = true;
    itemToDelete.value = item;
  } else {
    // @ts-ignore
    emit("close", item?.value || item, e);
  }
};
const handleSelect = (item: Item | String, e: any) => {
  emit("click", item, e);
};
const removeChip = () => {
  emit("close", itemToDelete?.value);
};
</script>
