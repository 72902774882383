<template>
  <MaireDialog
    :title="getDialogTitle"
    :description="getDialogDescription"
    :open="isOpen"
    :confirmDisabled="!name"
    confirmText="Save"
    :hideCancel="false"
    :onClose="onClose"
    :fn="saveAnalysis"
  >
    <div id="create_analysis_modal" class="inputs blue-input">
      <v-text-field
        v-model="name"
        variant="outlined"
        label="Name"
        placeholder="e.g. High volume online shopping analysis"
        required
        density="compact"
        persistent-placeholder
        autofocus
      />
      <v-text-field
        v-model="description"
        variant="outlined"
        label="Description"
        placeholder="e.g. Analysis of high volume online shopping keywords"
        density="compact"
      />
      <div v-if="allowSourceLabelEditing">
        <div class="title">
          <strong class="s pa-2">Source labels</strong>
        </div>
        <p class="text xx">
          These labels will define the set of keywords that the analysis will be built upon. All other labels
          that you choose to include in your analysis will only be shown in relation to the source labels. You
          can change source labels at any point.
        </p>
        <div class="chips">
          <MaireChipsInput
            label="Add label(s)"
            icon="far fa-tag"
            field="text"
            multiple
            :limit="500"
            :itemList="companyLabels"
            :items="localSourceLabels"
            closable
            :link-fn="(label: Label) => `/labels/${label.id}`"
            @add="handleSourceLabelAdd"
            @remove="handleSourceLabelRemove"
          />
        </div>
      </div>
    </div>
  </MaireDialog>
</template>
<style scoped>
#create_analysis_modal .v-label {
  /* margin: 12px !important; */
  word-break: break-word;
  white-space: normal;
  color: black;
}

#create_analysis_modal p {
  margin: 24px 0px;
}

input {
  color: black !important;
}
</style>

<script lang="ts" setup>
import { computed, defineProps, onMounted, Ref, ref } from "vue";
import { AnalysisDetails } from "@/store/modules/analysis/types";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import MaireChipsInput from "../MaireChipsInput/MaireChipsInput.vue";
import { store } from "@/store";
import { Label } from "@/store/modules/labels/types";

const props = defineProps({
  kwIds: {
    type: Array as () => number[],
    required: false,
  },
  details: {
    type: Object as () => AnalysisDetails,
    required: false,
  },
  type: {
    type: String,
    default: "Analysis",
    required: false,
  },
  clonedFrom: {
    type: Number,
    required: false,
  },
  open: {
    type: Boolean,
    required: false,
    default: true,
  },
  onSave: {
    type: Function,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
});

const name = ref("");
const description = ref("");
const isOpen = ref(true);

const labelsById = computed(() => store.state.labels.labelsById);
const companyLabels = computed(() => Object.values(labelsById.value));
const localSourceLabels: Ref<Label[]> = ref([]);
const handleSourceLabelAdd = (labelIds: number[]) => {
  const labels = labelIds.map((id) => labelsById.value?.[id]);
  localSourceLabels.value = labels;
};
const handleSourceLabelRemove = (label: Label) => {
  localSourceLabels.value = localSourceLabels.value.filter((lbl) => lbl.id !== label.id);
};
const allowSourceLabelEditing = computed(() => props.type === "Analysis" && !props.details?.id);

onMounted(() => {
  if (props.details) {
    name.value = props.details.name;
    description.value = props.details.description;
    localSourceLabels.value = props.details.labels?.map((lblId) => labelsById.value?.[lblId]) ?? [];
  }
});
const saveAnalysis = () => {
  if (!name.value) {
    console.log("Missing analysis name");
    return;
  }
  props.onSave({
    id: props.details?.id,
    name: name.value,
    description: description.value,
    clonedFrom: props.clonedFrom,
    labels: allowSourceLabelEditing.value ? localSourceLabels.value.map((label) => label.id) : undefined,
  });
  props.onClose(name.value);
};

const getDialogTitle = computed(() => {
  let text = props.details?.id ? "Update" : "Create new";
  return `${text} ${props.type}`;
});
const getDialogDescription = computed(() => {
  return props.kwIds?.length ? `${props.kwIds.length} keywords are initially included in the analysis. ` : "";
});
</script>
