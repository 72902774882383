<template>
  <div class="center">
    <img :src="`${publicPath}images/box-pencil.png`" alt="Decorative grayscale box" />
    <p class="l title">{{ msg }}</p>
    <p class="s decription">{{ details }}</p>
    <MaireButton comfortable text="Go back" @click="previousPage" />
  </div>
</template>

<style scoped>
.center {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80%;
  width: 100%;
}
p {
    margin-bottom: 24px;
}
img {
  height: 200px;
  margin-bottom: 48px;
}
</style>
<script lang="ts" setup>
import { defineProps } from "vue";
import MaireButton from "../ButtonBlock/MaireButton.vue";
import { useRouter } from "vue-router";
const publicPath = process.env.BASE_URL;
const router = useRouter();
defineProps({
  msg: { type: String, required: false, default: "Oh no! Something unexpected happened" },
  details: { type: String, required: false, default: "Maire employees have been notified of the issue." },
});

const previousPage = () => router.back();
</script>
