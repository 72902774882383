<template>
  <MaireTooltip :hidden="!(disabled && disabledMessage)">
    <template #trigger>
      <div :class="`${!!disabled ? 'disabled' : ''}`">
        <!-- <div :class="`${!!disabled ? 'disabled' : ''}`" @mousemove="setPos" @mouseleave="unsetPos"> -->
        <v-btn
          v-if="!onClick"
          :to="link"
          :disabled="!!disabled"
          :class="`maire-button button ${imageUrl ? 'small-button' : ''} ${compact ? 'compact' : ''} ${
            comfortable ? 'comfortable' : ''
          } ${inverse ? 'inverse' : ''} ${dense ? 'dense' : ''} ${bgColor ? bgColor : ''}`"
          :density="dense ? 'default' : compact ? 'compact' : comfortable ? 'comfortable' : 'default'"
        >
          <v-icon class="prepend-icon" v-if="icon">{{ icon }}</v-icon>
          <span class="noevents">{{ text }}</span>
          <v-icon class="append-icon" v-if="appendIcon">{{ appendIcon }}</v-icon>
          <span class="noevents" v-if="subtext" style="font-size: 8px"><br />{{ subtext }}</span>
        </v-btn>
        <v-btn
          v-if="onClick"
          @click="onClick"
          :disabled="!!disabled"
          :class="`button ${imageUrl ? 'small-button' : ''} ${fixed ? 'fixed' : ''} ${
            compact ? 'compact' : ''
          } ${comfortable ? 'comfortable' : ''} ${inverse ? 'inverse' : ''} ${dense ? 'dense' : ''}  ${
            bgColor ? bgColor : ''
          }`"
          color="default"
          variant="outlined"
          :density="dense ? 'default' : compact ? 'compact' : comfortable ? 'comfortable' : 'default'"
        >
          <v-progress-circular size="18" indeterminate v-if="isLoading" />
          <span class="noevents" v-if="!isLoading">
            <v-icon class="prepend-icon" v-if="icon">{{ icon }}</v-icon>
            <span class="noevents">{{ text }}</span>
            <span class="noevents" v-if="subtext" style="font-size: 8px"><br />{{ subtext }}</span>
            <v-icon class="append-icon" v-if="appendIcon">{{ appendIcon }}</v-icon>
          </span>
        </v-btn>
      </div>
    </template>
    <template #content>
      <p class="xs" style="color: white; font-weight: 400">{{ disabledMessage }}</p>
    </template>
  </MaireTooltip>
</template>
<style scoped>
.disabled {
  cursor: not-allowed !important;
}
.tooltip {
  position: fixed;
  text-align: left;
  margin-top: -20px;
  margin-left: 20px;
  padding: 12px 24px;
  z-index: 999;
  background-color: rgb(var(--v-theme-mairePink));
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.3)) !important;
  border-radius: 10px;
  color: white;
  font-size: 10px;
}
.prepend-icon {
  margin-right: 8px;
}
.append-icon {
  margin-left: 8px;
}
.fixed {
  position: fixed !important;
  width: 300px !important;
}

.button {
  box-shadow: none;
  border-radius: 8px;
  background-color: #e500ff;
  color: white;
  text-align: center;
  font-weight: 800;
  width: 100%;
  padding: 25px;
  vertical-align: bottom;
  font-family: "Nunito Sans";
  cursor: pointer;
  display: flex;
}
.comfortable {
  padding: 20px;
}

.comfortable {
  font-size: 12px !important;
}

.inverse {
  background-color: rgba(0, 0, 0, 0);
  color: rgb(var(--v-theme-mairePurple));
  border: 1px solid rgb(var(--v-theme-mairePurple));
}
.teal {
  background-color: rgba(0, 0, 0, 0);
  color: rgb(var(--v-theme-maireTeal));
  border: 1px solid rgb(var(--v-theme-maireTeal));
}
.button:hover {
  filter: brightness(1.1);
}
.small-button {
  max-width: 150px;
}
.compact {
  padding: 14px;
}

.compact {
  font-size: 10px !important;
}
.dense {
  padding: 4px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import MaireTooltip from "../MaireTooltip/MaireTooltip.vue";

export default defineComponent({
  name: "MaireButton",
  // data: () => ({
  //   left: 0,
  //   top: 0,
  // }),
  props: {
    text: String,
    link: String,
    disabled: Boolean,
    bgColor: { type: String, required: false },
    disabledMessage: { type: String, required: false },
    imageUrl: String,
    subtext: { type: String, required: false },
    icon: { type: String, required: false },
    appendIcon: { type: String, required: false },
    dense: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    comfortable: { type: Boolean, default: false },
    inverse: { type: Boolean, default: false },
    fixed: { type: Boolean, required: false },
    onClick: { type: Function, required: false },
    isLoading: { type: Boolean, default: false },
  },
  // methods: {
  //   setPos(ev: any) {
  //     this.left = ev.clientX;
  //     this.top = ev.clientY;
  //   },
  //   unsetPos() {
  //     this.left = 0;
  //     this.top = 0;
  //   },
  // },
  components: { MaireTooltip },
});
</script>
