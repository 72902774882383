<template>
  <div :class="classList">
    <v-list-item
      :class="{ 'active-item': active, 'smaller-font': true }"
      :title="text"
      :to="itemLink"
      :key="text"
      :active="active"
      density="compact"
      @click="() => toggle(new Set([text]))"
    >
      <template v-slot:prepend>
        <v-img v-if="image" :width="45" style="margin-right: 12px" :src="image"></v-img>
        <v-icon v-else class="menu-prepend-icon">
          {{ icon ?? "fa-fw" }}
        </v-icon>
      </template>
      <template v-slot:append v-if="items?.length">
        <v-icon class="analysis-view-toggle-icon inline">
          {{ active ? "fa-regular fa-caret-up" : "fa-regular fa-caret-down" }}
        </v-icon>
      </template>
    </v-list-item>

    <v-list v-if="active && items?.length" class="pa-0 sublist">
      <SideBarItem
        :key="item.text"
        :link="item.link"
        :icon="item.icon"
        :text="item.text"
        :items="item.items"
        :activePath="activePath"
        :action="item.action"
        :classList="item.classList"
        @toggle="toggle"
        v-for="item in items.filter(item => !item.hidden)"
      />
    </v-list>
  </div>
</template>
<style scoped>
.sublist {
  position: relative;
  max-height: 40vh;
  overflow: scroll;
  scrollbar-width: none;
}
</style>

<script lang="ts" setup>
export interface MenuItem {
  text: string;
  link?: string;
  action?: Function;
  icon?: string;
  image?: string;
  items?: MenuItem[];
  hidden?: boolean;
  activePath?: Set<string>;
  classList?: string[];
}
import { computed, defineProps, defineEmits } from "vue";
const props = defineProps<MenuItem>();
const emit = defineEmits(["toggle"]);
const toggle = (links: Set<string>) => {
  if (props.action) {
    props.action();
    return;
  }
  emit("toggle", new Set([...new Set([props.text]), ...links]));
};
const active = computed(() => props.activePath && props.activePath.has(props.text));
const itemLink = computed(() => {
  return (props.items?.length === 1 && props.items?.[0].link)  ? props.items?.[0].link : props.link;
});
</script>
