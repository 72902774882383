<template>
  <div class="search_bar">
    <v-text-field
      v-model="searchWord"
      label="Search items"
      density="compact"
      clearable
      :append-inner-icon="searchWord ? '' : 'mdi:mdi-magnify'"
      class="search_bar"
      variant="outlined"
      :hide-details="hideDetails"
    ></v-text-field>
    <Transition>
      <div v-if="magicSearchEnabled && (searchWord || thinking)" class="avatar-container">
        <img
          :src="`${publicPath}images/maire_avatar.png`"
          @click="magicSearch"
          alt="Avatar"
          :class="`${thinking ? 'thinking' : ''} avatar`"
        />
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.search_bar {
  width: 100%;
  position: relative;
  z-index: 2;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "SearchBar",
  props: {
    magicSearchEnabled: { required: false, type: Boolean, default: false },
    hideDetails: { required: false, type: Boolean, default: false },
  },
  emits: ["set"],
  data: () => ({
    searchWord: "",
    searchTimeout: null as any,
  }),
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    thinking() {
      return this.store.state.loading.thinking;
    },
    searchWordState() {
      return this.store.state.analysis.currentFilters.searchWord;
    },
  },
  watch: {
    searchWord() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.$emit("set", this.searchWord);
        this.store.commit("analysis/setSearchWord", this.searchWord);
      }, 500);
    },
    searchWordState() {
      this.searchWord = this.searchWordState;
    },
  },
  mounted() {
    this.searchWord = this.searchWordState;
  },
  unmounted() {
    clearTimeout(this.searchTimeout);
    this.store.commit("analysis/setSearchWord", "");
  },
  methods: {
    magicSearch() {
      this.store.dispatch("magicSearch", this.searchWord);
      this.searchWord = "";
    },
  },
});
</script>
