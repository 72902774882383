<template>
  <strong v-if="!variants?.length">
    <strong> {{ fieldValue }}</strong>
  </strong>
  <MaireTooltip v-else>
    <template #trigger>
      <strong>
        {{ fieldValue }}
        <span style="font-weight: 400">
          {{ variants?.length > 0 ? `(+${variants.length} variants)` : "" }}
        </span>
      </strong>
    </template>
    <template #content>
      <span>
        <ul>
          <li :key="variant" v-for="variant in variants">{{ variant }}</li>
        </ul>
      </span>
    </template>
  </MaireTooltip>
</template>

<script lang="ts" setup>
import { defineProps, computed } from "vue";
import MaireTooltip from "@/components/MaireTooltip/MaireTooltip.vue";

const props = defineProps({
  fieldValue: [Number, String],
  field: Object,
  item: Object,
});
const variants = computed(() => {
  return props.item?.close_variants;
});
</script>
