<template>
  <div @click="(e) => clickLabel(label, e)" class="category-input-field inline">
    <v-chip
      :style="{ 'background-color': getLabelColor(label.text), color: 'white' }"
      v-if="true"
      :density="density"
      class="label"
    >
      <MaireTooltip>
        <template #trigger>
          <span
            class="text-truncate label"
            style="max-width: 50px; display: block"
            @mouseenter="tooltipVisible = label.id"
            @mouseleave="tooltipVisible = undefined"
          >
            {{ label.text }}
          </span>
        </template>
        <template #content>
          <strong>{{ getLabelInfo(label).text }}</strong
          ><br />
          <p style="color: white !important"><strong>Creator:</strong> {{ getLabelInfo(label).user }}</p>
          <p style="color: white !important">
            <strong>Kws with label:</strong> {{ getLabelInfo(label).keyword_count }}
          </p>
          <p style="color: white !important">
            <strong>Analyses with label:</strong> {{ getLabelInfo(label).analyses?.length ?? 0 }}
          </p>
        </template>
      </MaireTooltip>

      <template #close>
        <v-icon v-if="closable" icon="mdi:mdi-close-circle" @click.stop="() => closeLabel(label)" />
      </template>
    </v-chip>
  </div>
</template>
<style scoped>
.plusIcon {
  color: rgba(var(--v-theme-mairePurple)) !important;
  border-radius: 25px;
  margin: 5px;
  padding: 0px;
  text-align: center;
  outline: 2px solid rgba(var(--v-theme-mairePurple));
}
.labelInput {
  margin-top: 10px;
}

.label span {
  max-width: 50px;
  font-size: 12px;
}
.label {
  cursor: pointer;
  position: relative;
  margin: 2px;
}
.danger {
  background-color: red !important;
  color: white !important;
  border-style: none;
}
.inverse {
  background-color: rgba(var(--v-theme-mairePurpleSecondary)) !important;
}

.danger:hover {
  border-style: none;
  color: white !important;
}

p {
  color: white;
  font-size: 12px;
  padding: 3px 10px;
}
</style>

<script lang="ts" setup>
import { ref, defineEmits, defineProps, computed, Ref, PropType } from "vue";
import { useLabelColor } from "@/composables/useLabelColor";
import MaireTooltip from "@/components/MaireTooltip/MaireTooltip.vue";
import { useStore } from "@/store";
import { Label, ViewLabel } from "@/store/modules/labels/types";
const store = useStore();
const { getLabelColor } = useLabelColor();

const emit = defineEmits(["clickLabel", "clickClose"]);
const clickLabel = (label: Label | ViewLabel, e: Event) => {
  emit("clickLabel", label, e);
};
const closeLabel = (label: Label) => {
  emit("clickClose", label);
};

defineProps({
  label: { type: Object as PropType<Label | ViewLabel>, required: true },
  closable: { type: Boolean, required: false, default: true },
  density: { type: String, required: false },
});

const labels = computed(() => {
  return store.state.labels.labelsById;
});

const companyUsers = computed(() => {
  return Object.fromEntries(store.state.company.active_users?.map((user) => [user.id, user]) ?? []);
});

const getLabelInfo = (label: ViewLabel | Label) => {
  const lbl = label.id && labels.value?.[label.id];
  let user;
  if (lbl && lbl?.user_id) {
    user = companyUsers.value?.[lbl.user_id];
  }
  return {
    user: user ? `${user.first_name} ${user.last_name}` : "",
    ...label,
    ...(lbl ?? {}),
  };
};
const tooltipVisible: Ref<undefined | number> = ref(undefined);
</script>
