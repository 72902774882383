<template>
  <div @click="toggle" class="inline-block custom-density" id="graph-toggle" v-if="icon && text">
    <div class="xs inline" v-if="icon"><v-icon>{{icon ?? 'far fa-eye-slash'}}</v-icon></div>
    <span v-if="text" class="xs" style="margin-left: 0.5em">{{ text }}</span>
  </div>
</template>
<style scoped>
#graph-toggle {
  cursor: pointer;
  float: right;
  margin-left: auto;
  color: rgb(var(--v-theme-mairePurpleSecondary));
  align-self: center;
}
</style>

<script lang="ts" setup>
interface IconToggleState {
  icon: string;
  text: string;
}
import { computed, defineProps, PropType, defineEmits } from "vue";
const emit = defineEmits(["toggle"]);
const props = defineProps({
  state: { type: Number, required: true },
  states: { type: Object as PropType<Record<number, IconToggleState>>, required: true },
  tooltip: {
    type: String,
    requided: false,
  },
});
const currentState = computed(() => props.states?.[props.state]);
const icon = computed(() => currentState.value?.icon);
const text = computed(() => currentState.value?.text);
const toggle = () => emit("toggle");
</script>
