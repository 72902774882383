// Styles
import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-pro/css/all.css";

import "vuetify/styles";
import { aliases, fa } from "vuetify/iconsets/fa";
import { mdi } from "vuetify/iconsets/mdi";

// Vuetify
import { createVuetify } from "vuetify";

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: "#3700B3",
    surface: "#FFFFFF",
    primary: "#293657",
    "primary-darken-1": "#3700B3",
    secondary: "#03DAC6",
    "secondary-darken-1": "#018786",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    paragraph: "#4d4d4d",
    maireGrey0: "#747D90",
    maireGrey: "#939EB4",
    maireGrey2: "#BCC3D2",
    maireGrey3: "#E4E8F1",
    mairePurple: "#B500FF",
    mairePurpleSecondary: "#8200FF",
    maireBlue: "#3E54D3",
    maireDarkBlue: "#293657",
    maireTeal: "#14CCC9",
    maireOcean: "#4FE0B5",
    mairePink: "#E500FF",
    maireLightPink: "#FF73FF",
    maireYellow: "#FFD100",
    maireViolet: "#B500FF",
  },
};

const drawerTheme = {
  dark: false,
  colors: {
    surface: "#FFFFFF",
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme,
      drawerTheme,
    },
  },
  icons: {
    defaultSet: "fa",
    aliases,
    sets: {
      fa,
      mdi,
    },
  },
});
