import { ActionContext, ActionTree, Module, MutationTree } from "vuex";
import { State as RootState } from "@/store/index";
import { Discovery, DiscoveryState } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import * as api from "@/api";

const parseAxiosError = (error: AxiosError) => {
  console.log(error.response);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.statusText) {
      // @ts-ignore
      return `${error.response.statusText}: ${JSON.stringify(error.response.data?.detail)}`;
    }
    // @ts-ignore
    return JSON.stringify(error?.response?.data?.detail) ?? "Client error.";
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return "Server error.";
  } else {
    // Something happened in setting up the request that triggered an Error
    return error.message;
  }
};

const getDefaultState = (): DiscoveryState => ({
  discoveries: [],
});

// Mutations
const mutations: MutationTree<DiscoveryState> = {
  resetState: (state) => {
    state = Object.assign(state, getDefaultState());
  },
  setDiscoveries: (state, discoveries: Discovery[]) => {
    discoveries.sort((a: Discovery, b: Discovery) => a.name.localeCompare(b.name));
    state.discoveries = discoveries.map((d) => ({ ...d, originalLabels: JSON.stringify(d.labels) }));
  },
  updateDiscovery: (state, discovery: Discovery) => {
    state.discoveries = state.discoveries.map((d) => (d.id === discovery.id ? discovery : d));
  },
  addDiscovery: (state, discovery: Discovery) => {
    state.discoveries.push(discovery);
  },
  deleteDiscovery: (state, discoveryId: number) => {
    state.discoveries = state.discoveries.filter((d) => d.id !== discoveryId);
  },
};

// Actions
const actions: ActionTree<DiscoveryState, RootState> = {
  async trigger({ commit }: ActionContext<DiscoveryState, RootState>, discoveryId: number) {
    const r = await api.triggerDiscovery(discoveryId);
    if (r.status === 200) {
      commit("setDiscoveries", r.data);
    }
  },
  async fetchDiscoveries({ commit }: ActionContext<DiscoveryState, RootState>) {
    const r = await api.fetchDiscoveries();
    if (r.status === 200) {
      commit("setDiscoveries", r.data);
    }
  },
  async delete({ commit }: ActionContext<DiscoveryState, RootState>, discovery: Discovery) {
    if (!discovery.id) {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
      return;
    }
    const r = await api.deleteDiscovery(discovery.id);
    // filter out the deleted discovery
    if (r.status === 200) {
      commit("setSuccessText", "Discovery deleted", { root: true });
      commit("deleteDiscovery", discovery.id);
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },
  async updateDiscovery(
    { commit }: ActionContext<DiscoveryState, RootState>,
    { id, updates, skipNotify }: { id: number; updates: Partial<Discovery>; skipNotify?: false }
  ) {
    if (!id || !updates) {
      console.error("Tried to update discovery, but there was nothing to update.");
    }
    try {
      const r = await api.updateDiscovery(id, updates);
      const d = { ...r.data, originalLabels: JSON.stringify(r.data.labels) };
      commit("updateDiscovery", d);
      if (!skipNotify) commit("setSuccessText", `Discovery updated ${JSON.stringify(updates)}`, { root: true });
      return r.data;
    } catch (e) {
      if (!skipNotify) commit("setErrorText", `Updating discovery failed.`, { root: true });
    }
  },
  async createOrUpdateDiscovery({ commit }: ActionContext<DiscoveryState, RootState>, discovery: Discovery) {
    let r: AxiosResponse;
    try {
      let d: Discovery;
      if (discovery.id) {
        r = await api.updateDiscovery(discovery.id, discovery);
        d = { ...r.data, originalLabels: JSON.stringify(r.data.labels) };
        commit("updateDiscovery", d);
      } else {
        r = await api.createDiscovery(discovery);
        d = { ...r.data, originalLabels: JSON.stringify(r.data.labels) };
        commit("addDiscovery", d);
      }
      commit("setSuccessText", `Discovery ${discovery.id ? "updated" : "created"}!`, { root: true });
      return d;
    } catch (e) {
      console.log(e);
      const msg = `Failed to create/update Discovery. ${parseAxiosError(e as AxiosError)}`;
      console.error("Error updating discovery", msg);
      commit("setErrorText", msg, { root: true });
      return;
    }
  },
};

const DiscoveryModule: Module<DiscoveryState, RootState> = {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
};

export default DiscoveryModule;
