<template>
  <v-container>
    <v-row class="text-start">
      <v-col cols="12">
        <div class="header">
          <h3 class="mb-3 primary text-primary">Company settings</h3>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <GoogleIntegrationWidget />
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.info {
  padding-bottom: 20px;
}

.text-paragraph {
  padding: 10px 0px 20px;
}

.usage_table {
  /* box-shadow: 0px 0px 20px rgba(41, 54, 87, 0.1); */
  color: #293657;
}

.custom-placeholer-color input::placeholder {
  color: rgb(0, 0, 136) !important;
  opacity: 1;
  font-size: 14px;
}

.billing-container {
  padding: 12px 0px;
  width: 100%;
  margin-top: 50px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import GoogleIntegrationWidget from "@/components/GoogleIntegrationWidget/GoogleIntegrationWidget.vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "SettingsView",
  data: () => {},
  methods: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  mounted() {
    const queryParams = this.route.query;
    const scope = queryParams?.scope;
    const code = queryParams?.code;
    const state = queryParams?.state;
    if (scope && code && state) {
      this.store.dispatch("oAuth", { scope, code, state });
    }
  },
  computed: {
    user() {
      return this.store.state.user;
    },
    company() {
      return this.store.state.company;
    },
    isLoading() {
      return this.store.state.loading.loadingKeywords;
    },
    creditResetDate() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth() + 1, 1).toDateString();
    },
    state() {
      return this.route.query?.state;
    },
  },
  components: { GoogleIntegrationWidget },
});
</script>
