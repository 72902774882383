<template>
  <strong v-if="!tooBig">{{ props.fieldValue }}</strong>
  <div v-else>
    <MaireTooltip>
      <template #trigger>
        <strong class="wrap">{{ props.fieldValue?.slice(0, maxLength) }}</strong>
      </template>
      <template #content>
        <strong>Discovery name</strong> <br />
        {{ props.fieldValue }}
      </template>
    </MaireTooltip>
  </div>
</template>
<script lang="ts" setup>
import { defineProps, ref } from "vue";
import MaireTooltip from "@/components/MaireTooltip/MaireTooltip.vue";

const props = defineProps({
  fieldValue: { type: String, required: true, default: "" },
  field: Object,
});
const maxLength = ref(50);
const tooBig = (props.fieldValue?.length ?? 0) > maxLength.value;
</script>
