import axios, { AxiosResponse } from "axios";
import { csvToArray, BACKEND_PATH } from "./helpers";
import router from "./router";
import { ChatMessage, UserConsent, store } from "./store";
import { Analysis, ItemShares, AnalysisKeywordVolumes } from "./store/modules/analysis/types";
import { ViewLabel } from "./store/modules/labels/types";
import { View } from "./store/modules/analysis/types";
import { Discovery } from "./store/modules/discovery/types";

interface UserCreate {
  firstName: string;
  lastName: string;
  email: string;
  companyId: string;
  password: string;
  type: string;
}

interface UserEdit {
  type: string;
}

interface CompanyCreate {
  name: string;
  domain: string;
  location: string;
  language: string;
  customerAveragePurchase: string;
  descriptiveWords: string;
}

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.data?.detail?.includes("Not authenticated")) {
      store.dispatch("clearEverything");
      if (!(router.currentRoute?.value?.name === "reset_password")) {
        router.push("/login");
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
// object to store ongoing requests cancel tokens
const pendingRequests = new Map();

// next we set up the Request Interceptor, this logic triggers
// before each request that we send
axios.interceptors.request.use(
  (config) => {
    // generate an identifier for each request
    let requestIdentifier = `${config.url}_${config.method}`;
    if (config.data) {
      requestIdentifier = `${requestIdentifier}_${config.data?.field ?? ""}_${
        config.data?.include_only_new ?? ""
      }`;
    }

    // check if there is already a pending request with the same identifier
    if (pendingRequests.has(requestIdentifier)) {
      const cancelTokenSource = pendingRequests.get(requestIdentifier);
      // cancel the previous request
      cancelTokenSource.cancel(`Cancelled due to new request: ${requestIdentifier}`);
    }

    // create a new CancelToken
    const newCancelTokenSource = axios.CancelToken.source();
    config.cancelToken = newCancelTokenSource.token;
    // store the new cancel token source in the map
    pendingRequests.set(requestIdentifier, newCancelTokenSource);
    return config;
  },
  (error) => {
    // return the error if the request fails
    return Promise.reject(error);
  }
);

// here we set up the Response Interceptor, this logic triggers
// before each response from the server comes
axios.interceptors.response.use(
  (response) => {
    // remove completed request from pending map
    let requestIdentifier = `${response.config.url}_${response.config.method}`;
    if (response.config.data) {
      const data = JSON.parse(response.config.data);
      requestIdentifier = `${requestIdentifier}_${data?.field ?? ""}_${data?.include_only_new ?? ""}`;
    }
    pendingRequests.delete(requestIdentifier);
    return response;
  },
  (error) => {
    // remove failed request from pending map
    if (error.config) {
      const data = JSON.parse(error.config.data ?? "");
      const requestIdentifier = `${error.config.url}_${error.config.method}_${data?.field ?? ""}_${
        data?.include_only_new ?? ""
      }`;
      pendingRequests.delete(requestIdentifier);
    }
    return Promise.reject(error);
  }
);

export const createUser = (user: UserCreate) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/admin/users`, {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    company_id: user.companyId,
    password: user.password,
    type: user.type,
  });
};

export const editUser = (userId: number, userData: UserEdit) => {
  const root = BACKEND_PATH;
  return axios.put(`${root}/admin/users/${userId}`, {
    type: userData.type,
  });
};

export const toggleUserActivity = (userId: number) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/admin/users/${userId}/toggle_activity`);
};

export const fetchCompanies = () => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/admin/companies`);
};

export const me = () => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/me`);
};

export const removeLabelFromKeyword = (keyword_id: number, label: string, savedFilterId?: number) => {
  const root = BACKEND_PATH;
  const data = { labels: [label], localized_keyword_ids: [keyword_id] };
  return axios.delete(`${root}/analysis_filters/${savedFilterId}/labels`, { data });
};

export const performance = (country: string) => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/performance`, { params: { country: country } });
};

export const gsc_performance = () => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/gsc`);
};

export const gsc_countries = () => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/gsc_countries`);
};

export const gsc_chart_data = (country: string) => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/gsc_chart_data`, { params: { country: country } });
};

export const gsc_sites = () => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/gsc_sites`);
};

export const ga_properties = () => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/ga_properties`);
};

export const select_gsc_site = (site_url: string) => {
  const root = BACKEND_PATH;
  const params = { site_url };
  return axios.get(`${root}/gsc_sites/select`, { params: params });
};

export const select_ga_property = (property_id: string) => {
  const root = BACKEND_PATH;
  const params = { property_id };
  return axios.get(`${root}/ga_properties/select`, { params: params });
};

export const saveConsent = (consent: UserConsent) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/me/consent`, { consent: consent });
};

export const fetchCSV = (url: string) => {
  return axios.get(url).then((r) => {
    const arr = csvToArray(r.data);
    return arr;
  });
};
export const sendMessage = (conversation: ChatMessage[]) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/chat`, {
    chat: { messages: conversation },
    context: {
      article: store.state.article,
      keyword_id: store.state.keywords.keywordWithDetails?.id,
    },
  });
};

export const loginAs = (userId: number) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/admin/impersonate`, {
    user_id: userId,
  });
};

export const createCompany = (company: CompanyCreate) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/admin/companies`, {
    name: company.name,
    domain: company.domain,
    location: company.location,
    language: company.language,
    customer_average_purchase: company.customerAveragePurchase,
    descriptive_words: company.descriptiveWords,
  });
};

export const updateSourceLabels = (analysisId: number, labelIds: number[]) => {
  const root = BACKEND_PATH;
  return axios.put(`${root}/analysis_filters/${analysisId}`, { label_ids: labelIds });
};

export const createOrUpdateAnalysis = (filter: Analysis) => {
  const root = BACKEND_PATH;
  if (filter?.id) {
    return axios.put(`${root}/analysis_filters/${filter.id}`, { ...filter, label_ids: filter.labels });
  } else {
    return axios.post(`${root}/analysis_filters`, { ...filter, label_ids: filter.labels });
  }
};

export const deleteAnalysis = (filterId: number) => {
  const root = BACKEND_PATH;
  return axios.delete(`${root}/analysis_filters/${filterId}`);
};

export const fetchAnalyses = () => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/analysis_filters`);
};

export const fetchAnalysis = (id: number) => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/analysis_filters/${id}`);
};

export const fetchPredictions = ({ series }: { series: Record<string, Record<number, number[]>> }) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/forecasts/forecast`, { series });
};

export const fetchAnalysisLabelStats = (
  filterId: number,
  labels?: string[],
  viewId?: number,
  filters?: any
) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/analysis_filters/${filterId}/get_label_stats`, {
    labels: labels,
    view_id: viewId,
    filters,
  });
};
export const fetchAnalysisKwStats = ({
  analysisId,
  filters,
  timerange,
  comparison,
  field,
  descending,
  predictions,
  include_only_new,
  preset,
  comparisonType,
  labels,
}: {
  analysisId: number;
  filters: any;
  timerange: any;
  comparison: any;
  field?: string;
  descending?: boolean;
  predictions?: boolean;
  include_only_new?: boolean;
  preset?: string;
  comparisonType?: string;
  labels?: string[];
}) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/analysis_filters/${analysisId}/get_kw_stats`, {
    include_predictions: !!predictions,
    filters,
    timerange,
    comparison,
    field,
    descending,
    include_only_new,
    preset,
    comparisonType,
    labels,
  });
};
export const fetchMatchingKeywords = ({
  analysisId,
  filters,
  labels,
  field,
}: {
  analysisId: number;
  filters: any;
  labels: string[];
  field: string;
}) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/analysis_filters/${analysisId}/get_keywords`, {
    filters,
    labels,
    field,
  });
};

export const fetchMatchingKeywordsSummary = ({
  analysisId,
  filters,
  labels,
  field,
}: {
  analysisId: number;
  filters: any;
  labels: string[];
  field: string;
}) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/analysis_filters/${analysisId}/get_keywords_summary`, {
    filters,
    labels,
    field,
  });
};
export const fetchAnalysisSummary = ({ analysisId }: { analysisId: number }) => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/analysis_filters/${analysisId}/summary`);
};

export const fetchKwTimelineStats = (kwIds: number[], predictions?: boolean) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/discovery/get_timeline_stats`, {
    localized_keyword_ids: kwIds,
    include_predictions: !!predictions,
  });
};
export const fetchKwStatsForKeywordIds = ({
  timerange,
  comparison,
  include_only_new,
  field,
  preset,
  kwIds,
}: {
  timerange: any;
  comparison: any;
  kwIds: number[];
  include_only_new?: boolean;
  field?: string;
  preset?: string;
}) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/discovery/get_kw_stats`, {
    localized_keyword_ids: kwIds,
    timerange,
    comparison,
    include_only_new,
    field,
    preset,
  });
};

export const fetchKwVolumes = (kwId: number) => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/localized_keywords/${kwId}/volumes`);
};

export const fetchAnalysisKeywordVolumes = (filterId: number) => {
  const root = BACKEND_PATH;
  return axios.get<AnalysisKeywordVolumes>(`${root}/analysis_filters/${filterId}/keywords/volumes`);
};

export const downloadAnalysisVolumes = (analysis_id: number) => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/analysis_filters/${analysis_id}/volumes`);
};

// Fetch labels associated with an analysis filter
export const fetchLabelsInAnalysis = (analysis_filter_id: number) => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/analysis_filters/${analysis_filter_id}/filter_labels`);
};
// http://localhost:8080/api/analysis_filters/119/filter_labels
// Label (Company global labels) CRUD
export const fetchLabels = () => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/labels/metadata`);
};

// Creates a label
export const createLabel = (data: { text: string; color?: string }) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/labels/create`, data);
};

// Updates a label
export const updateLabel = (labelId: number, newLabel: { text?: string; color?: string }) => {
  const root = BACKEND_PATH;
  return axios.put(`${root}/labels/${labelId}`, newLabel);
};

// Delets a label and everything associated with it
export const deleteLabels = (labels: string[]) => {
  const root = BACKEND_PATH;
  return axios.delete(`${root}/labels`, {
    data: { labels },
  });
};

export const createFilterView = (filterId: number, view: View): Promise<AxiosResponse<View>> => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/analysis_filters/${filterId}/views`, {
    name: view.name,
    description: view.description,
  });
};

export const updateFilterView = (view: View): Promise<AxiosResponse<View>> => {
  const root = BACKEND_PATH;
  return axios.put(`${root}/analysis_filter_views/${view.id}`, view);
};

export const deleteFilterView = (viewId: number): Promise<AxiosResponse<View>> => {
  const root = BACKEND_PATH;
  return axios.delete(`${root}/analysis_filter_views/${viewId}`);
};

// Label-hierarchy CRUD
// Returns label-hierarchy for a view
export const fetchViewLabels = (viewId: number): Promise<AxiosResponse<ViewLabel[]>> => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/analysis_filter_views/${viewId}/labels`);
};

// Updates label hierarchy in one freaking go
export const updateViewLevels = (viewId: number, data: { [level: number]: string[] }[]) => {
  const root = BACKEND_PATH;
  return axios.put(`${root}/analysis_filter_views/${viewId}/levels`, data);
};

// Add label to multiple keywords
export const addLabelsToKeywords = (keywordIds: number[], labels: string[]) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/labels/localized_keywords`, { localized_keyword_ids: keywordIds, labels });
};

// Removes label from keywords
export const removeLabelFromKeywords = (labels: ViewLabel[], kwIds: number[]) => {
  const root = BACKEND_PATH;
  return axios.delete(`${root}/labels/localized_keywords`, {
    data: {
      localized_keyword_ids: kwIds,
      labels: labels.map((lbl) => lbl.text),
      labelIds: labels.map((lbl) => lbl.id),
    },
  });
};

export const fetchFilterKeywords = (filterId: number) => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/analysis_filters/${filterId}/keywords`);
};

export const addKeywordsToAnalysis = (kwIds: number[], filterId: number) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/analysis_filters/${filterId}/keywords`, { localized_keyword_ids: kwIds });
};

export const removeKeywordsFromAnalysis = (kwIds: number[], filterId: number) => {
  const root = BACKEND_PATH;
  return axios.delete(`${root}/analysis_filters/${filterId}/keywords`, {
    data: { localized_keyword_ids: kwIds },
  });
};

export const fetchKeywordsWithDuplicates = () => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/admin/keywords_with_duplicates`);
};

export const fetchLabelRulePreview = (rules: object) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/labels/preview?version=2`, rules);
};

export const validateLabelRule = (labelId: number, rules: object) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/labels/${labelId}/validate_rule`, rules);
};

export const fetchLabelRules = (labelId: number) => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/labels/${labelId}/rules`);
};

export const triggerLabelRule = (labelId: number, rules: object) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/labels/${labelId}/trigger_label_rules?version=2`, rules);
};

export const toggleLabelRule = (labelId: number, isActive: boolean) => {
  const root = BACKEND_PATH;
  return axios.put(`${root}/labels/${labelId}/toggle_label_rule`, undefined, {
    params: { is_active: isActive },
  });
};

export const createLabelRule = (labelId: number, rules: object) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/labels/${labelId}/rules?version=2`, rules);
};

export const changeKeywordParent = (currentParentId: number, newParentId: number) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/admin/keyword_change_parent`, {
    current_parent_id: currentParentId,
    new_parent_id: newParentId,
  });
};

export const fetchShares = (itemType: "analysis" | "view", itemId: number) => {
  const root = BACKEND_PATH;
  const item_path = itemType === "analysis" ? "analysis_filters" : "analysis_filter_views";
  return axios.get<ItemShares>(`${root}/${item_path}/${itemId}/shares`);
};

export const shareItem = (
  itemType: "analysis" | "view",
  itemId: number,
  data: {
    actorType: "user" | "company";
    actorIds: number[];
    editAccess: boolean;
  }
) => {
  const root = BACKEND_PATH;
  const item_path = itemType === "analysis" ? "analysis_filters" : "analysis_filter_views";
  return axios.post(`${root}/${item_path}/${itemId}/share`, {
    actor_type: data.actorType,
    actor_ids: data.actorIds,
    edit_access: data.editAccess,
  });
};

export const unShareItem = (
  itemType: "analysis" | "view",
  itemId: number,
  data: {
    actorType: "user" | "company";
    actorIds: number[];
  }
) => {
  const root = BACKEND_PATH;
  const item_path = itemType === "analysis" ? "analysis_filters" : "analysis_filter_views";
  return axios.post(`${root}/${item_path}/${itemId}/unshare`, {
    actor_type: data.actorType,
    actor_ids: data.actorIds,
  });
};

export const fetchFeatureGates = () => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/admin/feature_gates`);
};

export const enableFeatureGate = (featureName: string, actorId: string) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/admin/feature_gates/enable`, {
    name: featureName,
    actor_id: actorId,
  });
};

export const disableFeatureGate = (featureName: string, actorId: string) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/admin/feature_gates/disable`, {
    name: featureName,
    actor_id: actorId,
  });
};

export const triggerDiscovery = (discoveryId: number) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/discoveries/${discoveryId}/trigger`);
};
export const fetchDiscoveries = () => {
  const root = BACKEND_PATH;
  return axios.get(`${root}/discoveries`);
};
export const createDiscovery = (data: Discovery) => {
  const root = BACKEND_PATH;
  return axios.post(`${root}/discoveries`, { ...data, label_ids: data.labels?.map((d) => d.id) });
};
export const updateDiscovery = (id: number, discovery: Partial<Discovery>) => {
  const root = BACKEND_PATH;
  return axios.put(`${root}/discoveries/${id}`, {
    ...discovery,
    label_ids: discovery.labels?.map((d) => d.id),
  });
};
export const deleteDiscovery = (id: number) => {
  const root = BACKEND_PATH;
  return axios.delete(`${root}/discoveries/${id}`);
};

export default axios;
