<template>
  <div class="column-flex expand">
    <div class="levels maire-scrollbar">
        <div
          :class="`level-selector inputs drop-zone ${hoveringOn === level ? 'drop-highlight' : ''}`"
          :key="level"
          v-for="level in Object.keys(selected)"
          @dragenter.prevent="() => onDragEnter(level)"
          @dragleave.prevent
          @dragover.prevent
          @drop.prevent="onDrop($event, level)"
        >
          <MaireDialog
            :open="clearModalOpen === level"
            :title="`Do you want to unselect all labels from level ${level}?`"
            description="You need to manually add them back again in case you need them."
            :fn="() => clearLevel(level)"
            :onClose="() => (clearModalOpen = undefined)"
          />
          <div class="drag-el" :draggable="true" @dragstart="startDrag($event, level)">
            <div class="s strong level-selector-title">
              <div class="level-selector-header-row">
                <div @click="toggleLevel(level)" class="level-toggle">
                  <v-icon>{{
                    minimizedLevels?.includes(level) ? "far fa-caret-down" : "far fa-caret-up"
                  }}</v-icon>
                  <v-icon>far fa-grip-dots</v-icon>
                  <p class="s">Level {{ level }}</p>
                </div>
                <div class="level-action">
                  <v-icon
                    v-if="maxLevel === parseInt(level) && selected[parseInt(level)]?.length === 0"
                    @click="removeLevel(level)"
                    class="remove"
                    >fa-solid fa-trash</v-icon
                  >
                  <v-icon
                    v-if="selected[parseInt(level)]?.length > 0"
                    @click="clearModalOpen = level"
                    class="remove"
                    >fa-solid fa-broom-wide</v-icon
                  >
                </div>
              </div>
            </div>
            <Transition name="slide" tag="div">
              <div v-show="!minimizedLevels?.includes(level)" class="level-details">
                <MaireChipsInput
                  variant="outlined"
                  field="text"
                  :items="selected[parseInt(level)]"
                  :selected="selected[parseInt(level)]?.map((lbl) => lbl.id) ?? []"
                  :itemList="labels"
                  label="Add label"
                  :disabled="!labels.length"
                  @add="(labelIds: number[]) => replaceLevelLabels(level, labelIds)"
                  @remove="handleLabelClose"
                  :clearable="false"
                  hide-selected
                  multiple
                />
              </div>
            </Transition>
          </div>
        </div>
      <div class="add-level-button" v-if="structureValid" @click="() => addLevel()">
        <v-icon class="preventClick centeredIcon plusIcon"> fa-regular fa-plus </v-icon>
        <span class="s">Add level</span>
      </div>
    </div>
    <div class="actionbar-bottom-buttons">
      <div class="actionbar-bottom-button">
        <v-icon @click="emit('close')">mdi:mdi-chevron-left</v-icon>
        <div style="width: 100%; margin-left: 12px">
          <MaireButton
            text="Save"
            class="save-analysis-button"
            @click="saveStructure"
            :disabled="!structureValid"
            variant="contained"
            comfortable
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.slide-leave-active,
.slide-enter-active {
  transition: all 0.3s ease-in-out;
}
.slide-enter-to,
.slide-leave-from {
  max-height: 500px;
}
.slide-enter-from,
.slide-leave-to {
  max-height: 0px !important;
  opacity: 0;
  margin-top: -10px !important;
}
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px 0px;
  outline: 0px solid rgba(var(--v-theme-mairePurpleSecondary), 0.5);
}
.drop-zone.drop-highlight {
  background-color: #d1ecf1;
  outline: 4px solid rgba(var(--v-theme-mairePurpleSecondary), 0.5) !important;
  filter: brightness(95%);
}
.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  box-shadow: 2px solid black;
}
.remove {
  cursor: pointer;
  float: right;
}
.add-level-button {
  color: rgba(var(--v-theme-mairePurpleSecondary), 1);
  margin: 12px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.add-level-button span {
  margin-left: 5px;
  font-size: 12px;
}
.plusIcon {
  outline: 0.5px solid rgba(var(--v-theme-mairePurpleSecondary), 1) !important;
  /* outline-offset: 0.3em !important; */
  font-size: 14px;
}
.levels {
    flex: 1 1 80%;
    max-height: 100%;
    overflow: auto;
}
.expand {
  flex: 1 1 auto;
  max-width: 100%;
}
.actionbar-bottom-buttons {
  flex: 0 0 0%; /* Stays at the bottom, takes up 10% of the height */
  align-content: end;
}
.actionbar-bottom-button {
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.level-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
}
.level-action {
  float: right;
}
.level-action i {
  color: rgb(var(--v-theme-maireDarkBlue));
}
.level-selector {
  transition: all 0.3s ease-in-out;
  position: relative;
  padding: 0px 0;
}
.level-selector-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.level-details {
  padding-top: 12px;
}
</style>
<script lang="ts" setup>
// import MaireChip from "@/components/MaireChip/MaireChip.vue";
import MaireChipsInput from "@/components/MaireChipsInput/MaireChipsInput.vue";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import { computed, onMounted, Ref, ref, watch, defineProps, defineEmits, PropType } from "vue";
import { store } from "@/store";
import { ViewLabel } from "@/store/modules/labels/types";
import { viewLabelsToStructure } from "@/store/modules/labels/helpers";
const emit = defineEmits(["save", "close"]);

const props = defineProps({
  viewLabels: {
    type: Array as PropType<ViewLabel[]>,
    required: true,
  },
});

const hoveringOn = ref("");
const selected: Ref<{ [key: number]: any[] }> = ref({});
const clearModalOpen: Ref<number | string | undefined> = ref(undefined);

const labelsById = computed(() => {
  return store.state.labels.labelsById;
});
const companyLabels = computed(() => Object.values(labelsById.value));
const labelsInLevels = computed(
  () =>
    new Set(
      Object.values(selected.value)
        .flatMap((x: any) => x)
        .map((x: any) => x.id)
    )
);

const labels = computed(() => {
  return companyLabels.value.filter((x) => !labelsInLevels.value.has(x.id));
});

onMounted(() => {
  if (!labels.value?.length) {
    store.dispatch("labels/fetchLabels");
  }
});

// State management for expanded levels
const minimizedLevels: Ref<string[]> = ref([]);
const maxLevel = computed(() => {
  return Math.max(...Object.keys(selected.value ?? {}).map(Number));
});

const onDragEnter = (level: string) => {
  hoveringOn.value = level;
};

const startDrag = (evt: DragEvent, level: string) => {
  if (evt.dataTransfer) {
    evt.dataTransfer.dropEffect = "move";
    evt.dataTransfer.effectAllowed = "move";
    evt.dataTransfer.setData("text/plain", level);
  }
};
const onDrop = (evt: DragEvent, newLevel: string) => {
  const startLevel = evt?.dataTransfer?.getData("text/plain"); // works
  if (!newLevel || !startLevel) return;
  selected.value = {
    ...selected.value,
    [newLevel]: selected.value[parseInt(startLevel)],
    [startLevel]: selected.value[parseInt(newLevel)],
  };
  hoveringOn.value = "";
};
const addLevel = (before?: string) => {
  const newLevel = before ? parseInt(before) : Object.keys(selected.value ?? {}).length + 1;

  if (before) {
    const newSelected: Record<string, ViewLabel[]> = {};
    const entries = Object.entries(selected.value);
    entries.forEach(([level, labels]) => {
      if (parseInt(level) < newLevel) {
        newSelected[level] = labels;
      } else if (parseInt(level) === newLevel) {
        newSelected[level] = [];
        newSelected[parseInt(level) + 1] = labels;
      } else {
        newSelected[parseInt(level) + 1] = labels;
      }
    });
    selected.value = newSelected;
  } else if (newLevel) {
    selected.value[newLevel] = [];
  }
};
const clearLevel = (level: string) => {
  selected.value[parseInt(level)] = [];
  clearModalOpen.value = undefined;
};
const removeLevel = (level: string) => {
  if (parseInt(level) === maxLevel.value) {
    delete selected.value[parseInt(level)];
  }
};

const handleLabelClose = (label: ViewLabel) => {
  selected.value[label.level] = selected.value[label.level]?.filter((x) => x.text !== label.text) ?? [];
};

const replaceLevelLabels = (level: string, labelIds: number[]) => {
  selected.value[parseInt(level)] = labelIds.map((labelId) => labelsById.value?.[labelId]);
};

const saveStructure = () => {
  const viewLabelsNew = [] as ViewLabel[];
  Object.entries(selected.value).forEach(([level, labels]) => {
    labels.forEach((label: ViewLabel) => {
      label.level = parseInt(level);
      viewLabelsNew.push(label);
    });
  });
  emit("save", viewLabelsNew);
  emit("close");
};

const toggleLevel = (level: string) => {
  if (minimizedLevels.value.includes(level)) {
    minimizedLevels.value = minimizedLevels.value.filter((lv) => lv !== level);
  } else {
    minimizedLevels.value.push(level);
  }
};

const structureValid = computed(() => {
  return Object.values(selected.value)
    .slice(0, -1)
    .every((x: any) => x.length);
});

const resetUI = () => {
  if (props.viewLabels && Object.keys(props.viewLabels)?.length === 0) {
    selected.value = { 1: [] };
  } else {
    selected.value = viewLabelsToStructure(props.viewLabels);
  }
};

watch(props.viewLabels, resetUI, { immediate: true });
</script>
