<template>
    <div @click="toggleCheckbox" class="maire-checkbox">
      <MaireTooltip :hidden="!(tooltipTitle || tooltipDescription)">
        <template #trigger>
          <div :class="`checkbox ${classes}`">
            <v-checkbox
              :prepend-icon="icon"
              class="icon-gap"
              density="dense"
              :model-value="value"
              hide-details
            />
          </div>
        </template>
        <template #content>
          <div style="min-width: 150px">
            <strong v-if="tooltipTitle" style="color: white">{{ tooltipTitle }}</strong>
            <p v-if="tooltipDescription" style="color: white">{{ tooltipDescription }}</p>
          </div>
        </template>
      </MaireTooltip>
    </div>
  </template>
  <style scoped>
  .checkbox {
    background-color: rgba(var(--v-theme-mairePurpleSecondary), 1);
    border-radius: 5px;
    padding: 6px 6px;
    color: white;
    width: 100%;
    cursor: pointer;
  }
  </style>
  <script lang="ts" setup>
  import { defineProps, defineEmits } from "vue";
  import MaireTooltip from "@/components/MaireTooltip/MaireTooltip.vue";
  const props = defineProps({
    icon: { type: String, required: false },
    value: { type: Boolean, required: false },
    tooltipTitle: { type: String, required: false },
    tooltipDescription: { type: String, required: false },
    classes: { type: String, required: false, default: "" },
  });
  const emit = defineEmits(["toggle"]);
  // const handleToggle = (val: boolean) => emit("toggle", val);
  const toggleCheckbox = (event: MouseEvent) => {
    event.stopPropagation();
    emit("toggle", !props.value);
  };
  </script>
  