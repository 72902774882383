<template>
  <div ref="labelFilterContainer" class="filters">
    <ul>
      <li class="inline filter">
        <div class="label-filters">
          <div class="label-filter-title"></div>
          <div
            :class="`label-filters level ${selectedLevel === parseInt(level) ? 'selected-level' : ''}`"
            :key="level"
            v-for="[level, labels] in includeFilterLabelsByLevel"
          >
            <div class="level-content">
              <div class="level-title-row">
                <span class="s strong level-selector-title">
                  <div class="expand-action" @click="toggleLevel(level)">
                    <v-icon>{{
                      minimizedLevels?.includes(level) ? "far fa-caret-down" : "far fa-caret-up"
                    }}</v-icon>
                    Level {{ level }}
                  </div>
                </span>
                <div style="width: 100px; display: inline-block; margin-left: 12px">
                  <MaireButton
                    dense
                    inverse
                    :text="`${allLabelsSelectedInLevel(parseInt(level)) ? 'Unselect all' : 'Select all'}`"
                    :onClick="() => selectLabels(parseInt(level))"
                  />
                </div>
              </div>
              <div
                class="level-chips"
                v-show="!minimizedLevels?.includes(level)"
                style="display: block; margin-top: 12px"
              >
                <span :key="label.text" v-for="label in labels">
                  <MaireChip
                    :selected="label.checked"
                    :label="label.text"
                    @select="handleClick(label)"
                    overrideClose
                    density="comfortable"
                    :closable="false"
                  />
                </span>
              </div>
              <span
                class="level-view-selector"
                :class="`${selectedLevel === parseInt(level) ? 'icon-active' : ''}`"
              >
                <v-icon
                  @click="changeLevel(parseInt(level))"
                  @mouseenter="hoveringOn = level"
                  @mouseleave="hoveringOn = ''"
                  >fa-solid {{ getIconForLevel(level) }}</v-icon
                >
                <v-icon
                  v-show="overrideRecommendedLevel && selectedLevel === parseInt(level)"
                  @click="cancelOverride"
                  >fa-solid fa-turn-down-left</v-icon
                >
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<style scoped>
.filter {
  width: 100%;
}
.selected-level {
  z-index: 1 !important;
  width: 100%;
}
.selected-level::after {
  background-color: rgba(var(--v-theme-mairePurple), 0.05);
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  content: "";
  z-index: 0 !important;
  pointer-events: none;
}
.level-content {
  padding: 0px 12px;
}
.level-title-row {
  display: flex;
  align-items: center;
}
.expand-action {
  display: flex;
  align-items: center;
  gap: 12px;
}
.expand-action i {
  opacity: 0.5;
}
.level-chips {
  padding-bottom: 24px;
}
.level-view-selector {
  position: absolute;
  font-size: 10px;
  top: 10px;
  right: 4px;
  cursor: pointer;
}

.level-view-selector i {
  margin-right: 12px;
}
.icon-active {
  color: rgba(var(--v-theme-mairePurple), 1);
}

.filters {
  position: relative;
  transition: max-height 0.3s ease-in-out; /* Add transition to max-height changes */
}
.filters:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none;
}
.expand-action {
  cursor: pointer;
}
ul {
  padding: 0px 0px 0px;
}
.switch-btn {
  cursor: pointer;
  display: inline-block;
}
.label-filters {
  max-width: 100%;
  position: relative;
  padding: 8px 0px 8px;
}
.label-filter-title {
  margin-bottom: 4px;
}
.label-filters li {
  max-width: 100%;
}
li {
  display: inline-block;
  margin-bottom: 12px;
}
</style>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { store } from "@/store";
import { numberFormatter } from "@/components/helpers/helpers";
import MaireChip from "@/components/MaireChip/MaireChip.vue";
import MaireButton from "../ButtonBlock/MaireButton.vue";
import { CheckboxState } from "@/store/modules/analysis/types";
import { useStore } from "@/store";
import { ViewLabel } from "@/store/modules/labels/types";
import { viewLabelsToStructure } from "@/store/modules/labels/helpers";

export default defineComponent({
  name: "LabelFilters",
  props: {
    selectedLevel: {
      type: Number,
      required: true,
      default: 1,
    },
    viewId: {
      type: Number,
      required: true,
    },
    overrideRecommendedLevel: {
      type: Boolean,
      required: true,
    },
    changeLevel: {
      type: Function,
      required: true,
    },
    cancelOverride: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    minimizedLevels: [] as string[],
    overflowing: false,
    hoveringOn: "",
    labelFilterContainer: ref(null),
  }),
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    searchWord() {
      return this.store.state.analysis.currentFilters.searchWord;
    },
    viewLabels() {
      return this.store.state.labels.viewLabels?.[this.viewId] ?? [];
    },
    structure() {
      return viewLabelsToStructure(this.labelsWithLevels) ?? {};
    },
    labelsWithLevels() {
      const labelToLevelMap = {} as { [key: string]: number };
      this.viewLabels.forEach((label: ViewLabel) => {
        labelToLevelMap[label.text] = label.level;
      });
      return (
        this.store.state.analysis.currentFilters.labelFilters.include.labels?.map((label) => ({
          ...label,
          level: labelToLevelMap?.[label.text],
        })) ?? []
      );
    },
    includeFilterLabelsByLevel() {
      return Object.entries(this.structure);
    },
  },
  methods: {
    getIconForLevel(level: string) {
      if (this.overrideRecommendedLevel && this.selectedLevel === parseInt(level)) {
        return "fa-thumbtack";
      }
      return this.hoveringOn === level
        ? "fa-thumbtack"
        : this.selectedLevel === parseInt(level)
        ? "fa-eye"
        : "fa-eye-slash";
    },
    allLabelsSelectedInLevel(level: number) {
      return this.labelsWithLevels
        .filter((label) => label.level === level)
        .every((label: CheckboxState) => label.checked);
    },
    toggleLevel(level: string) {
      if (this.minimizedLevels.includes(level)) {
        this.minimizedLevels = this.minimizedLevels.filter((lv) => lv !== level);
      } else {
        this.minimizedLevels.push(level);
      }
    },
    selectLabels(level: number) {
      const labels = this.structure?.[level];
      if (this.allLabelsSelectedInLevel(level)) {
        store.dispatch("analysis/unselectLabels", labels);
      } else {
        store.dispatch("analysis/selectLabels", labels);
      }
    },
    numberFormatter,
    handleClick(cb: CheckboxState) {
      store.dispatch("analysis/toggleLabelFilter", { cb });
    },
  },
  components: {
    MaireChip,
    MaireButton,
  },
});
</script>
