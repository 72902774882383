<template>
  <v-container v-if="isLoading" id="keyword_view">
    <v-row class="text-start">
      <v-col cols="10">
        <div class="animation">
          <h3 class="mb-3 primary megatron" style="text-align: center">
            Interpreting signals from all around the internet
          </h3>
          <wait-animation message="Maire is analyzing the keyword." />
        </div>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-if="!isLoading && keyword">
    <div class="horizontal-padding">
      <v-row>
        <div class="keyword-research-text-container">
          <h3 class="mb-3 primary text-primary m">
            {{ keyword.keyword }}
          </h3>
        </div>
      </v-row>
      <div style="margin-top: -24px">
        <v-row>
          <v-col cols="2">
            <div class="masonry-item">
              <div class="border">
                <MaireCard title="Volume">
                  <h3 class="mega">
                    {{ numberFormatter(keyword.volume, 2) }}
                    <v-progress-linear
                      height="25"
                      rounded-bar
                      color="#14CCC9"
                      :model-value="(keyword.volume / 50000) * 100"
                    />
                  </h3>
                  <p class="">
                    Volume tells you how many times a month your potential customers are using this keyword.
                  </p>
                </MaireCard>
              </div>
            </div>
            <div class="masonry-item">
              <MaireCard title="Location" :inverse="true" class="maire-dark-card">
                <h4 class="mega">
                  {{ keyword.location_name }}
                </h4>
                <div class="ocean-title">Language</div>
                <h4 class="mega">
                  {{ keyword.language_name }}
                </h4>
                <img class="localization_image" :src="`${publicPath}location.png`" />
              </MaireCard>
            </div>
          </v-col>
          <v-col cols="7">
            <MaireTab
              :tabs="tabs"
              :selectedTab="selectedTab"
              :onSelect="(tab: string) => selectedTab = tab"
            />
            <div v-if="selectedTab === tabs[0].key">
              <div id="trends_section" class="maire-drop-shadow paper-background">
                <v-row>
                  <v-col cols="10">
                    <p>
                      Purple pillars tells you the exact monthly search volumes for this keyword. Turquoise
                      line tells you the weekly trend score for this keyword. This data is especially useful
                      for the most recent weeks, when the monthly volumes have not yet been published. Orange
                      line is Maire's prediction of search volumes for the upcoming year.
                    </p>
                    <div id="trend_chart" v-if="keyword">
                      <trend-chart />
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div v-if="selectedTab === tabs[1].key">
              <div class="serp_section">
                <v-row style="padding: 12px">
                  <v-col cols="12" class="maire-drop-shadow paper-background">
                    <div
                      class="minimized-categories pointer"
                      @click="categoriesExpanded = !categoriesExpanded"
                    >
                      <h4 class="m maire-purple inline" style="margin-right: 24px">
                        Organic results - content types
                      </h4>
                      <div class="minimized-category-cards inline" v-if="!categoriesExpanded">
                        <div
                          :key="category.category"
                          :class="`inline category-label ${category.category}`"
                          v-for="category in sortedCategories.slice(0, 8)"
                        >
                          <img
                            class="type_img"
                            :src="`${publicPath}images/${
                              category.category === 'Product/Service' ? 'Product' : category.category
                            }.png`"
                          />
                          <p class="inline">
                            {{ `${category.count}% ${category.category}` }}
                          </p>
                        </div>
                      </div>
                      <v-icon
                        style="float: right; padding-top: 12px"
                        :icon="`${categoriesExpanded ? 'mdi:mdi-chevron-up' : 'mdi:mdi-chevron-down'}`"
                      >
                      </v-icon>
                    </div>
                    <Transition name="fadeHeight">
                      <div v-if="categoriesExpanded" class="expanded-categories">
                        <v-col cols="12" class="nopadding d-flex flex-wrap">
                          <CardWithIcon
                            :hero="type.size"
                            :title="type.title"
                            :description="type.description"
                            :imgName="type.img"
                            :key="type.img"
                            v-for="type in Object.values(typeAllocation || {})"
                          />
                        </v-col>
                      </div>
                    </Transition>
                  </v-col>
                  <!-- <v-col
                cols="4"
                class="maire-drop-shadow paper-background"
              ></v-col> -->
                  <v-col cols="12" class="maire-drop-shadow paper-background" style="margin-top: 12px">
                    <div id="serp_table">
                      <serp-table></serp-table>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="text-start">
          <v-col cols="7"> </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>
<style scoped>
.expanded-categories {
  padding-top: 12px;
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.5s ease-in-out;
  max-height: 300px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  padding-top: 0px;
  max-height: 0px;
}
.category-label {
  display: inline-block;
  border-radius: 10px;
  padding: 12px 24px;
  margin-top: 12px;
  margin-left: 12px;
  font-weight: 800;
}

.category-label p {
  color: white;
}

.category-label img {
  height: 30px;
  padding: 0px 12px 0px 0px;
  margin: 0px 0px -8px -8px;
}

.masonry {
  margin-top: -22px;
  padding: 12px;
}
.masonry-item {
  width: 100%;
  margin-top: 10px;
}

.masonry-item h2 {
  text-align: left;
  margin-left: -12px;
}
.keyword_view {
  position: relative;
}

.animation {
  margin-top: 20vh;
}

.mega {
  font-size: 52px;
  padding: 20px;
  text-align: left;
}

.maire-dark-card .mega {
  text-align: center;
}

.megatron {
  font-size: 34px;
  text-align: left;
}

.card {
  width: 100% !;
}
.maire-dark-card img {
  width: 220px;
  margin-top: -20px;
}

.card h3 {
  font-size: 24px !important;
}

.maire-dark-card h4 {
  font-size: 16px;
  font-family: Plus Jakarta Sans;
  font-weight: 800;
  margin-bottom: -20px;
}

.maire-dark-card p {
  color: white;
  padding: 0 10px 0 10px;
  margin-top: -20px;
}
.localization_image {
  padding-top: 20px;
}

.center {
  text-align: center;
  width: 100%;
}

.border {
  height: 100%;
  border: #293657 0.08em solid !important;
  border-style: none !important;
}

.card {
  position: relative;
  text-align: center;
  margin-bottom: 12px;
  padding: 12px 24px;
  color: black;
  background-color: white;
  border-radius: 10px;
}

div.card-two-thirds {
  width: 100vh;
}

.card p {
  padding: 10px;
}

img {
  max-width: 100%;
  padding: 10px;
}

.card-title {
  color: #8200ff;
  font-size: 16px;
  font-weight: 800;
  font-family: "Nunito Sans", extrabold;
}

.ocean-title {
  color: #4fe0b5;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: -20px;
}

.card-title {
  color: #8200ff;
  font-size: 16px;
}

.teal {
  background-color: #14ccc9;
}

.violet {
  background-color: #b500ff;
}

.pink {
  background-color: #e500ff;
}

.inverse {
  color: white;
}

.inverse p {
  text-align: center;
}

.inverse .card-content .card-title {
  color: white;
}

.inverse .mega {
  color: white;
}

.keyword-research-text-container {
  padding: 12px 12px;
  width: 100%;
  margin-top: 30px;
}

.right-hand-nav {
  position: fixed;
  top: 100px;
  right: 50px;
  width: 200px;
}

.right-hand-nav-item {
  font-size: 14px;
  line-height: 14px;
  height: 25px;
  margin-top: 15px;
  color: #8200ff !important;
  border-bottom: #8200ff 2px solid;

  text-align: center;
}

.right-hand-nav-item a {
  color: #8200ff;
}

.v-progress-linear--rounded-bar {
  border-radius: 40px;
}

.content-container {
  padding-right: 30px;
}

#information {
  margin-left: 0px;
}

#trends_section {
  background-color: white;
}

#trend_chart {
  margin-left: -24px;
  margin-top: 24px;
}

#serp_table {
  margin-left: -4px;
  margin-right: -3.5px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import CardWithIcon from "@/components/SerpTable/CardWithIcon.vue";
import TrendChart from "@/components/TrendChart/TrendChart.vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import MaireCard from "@/components/MaireCard/MaireCard.vue";
import SerpTable from "@/components/SerpTable/SerpTable.vue";
import { recommendedCategories } from "@/components/ArticleGeneration/categories";
import MaireTab from "@/components/MaireTab/MaireTab.vue";
import { numberFormatter } from "@/components/helpers/helpers";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  data: () => ({
    selectedIntent: "Your Best Opportunities",
    publicPath: process.env.BASE_URL,
    tabs: [
      { key: "Trends", title: "Trends" },
      { key: "Search page results", title: "Search page results" },
    ],
    tab: "Trends",
    selectedTab: "Trends",
    timer: 0,
    position: { x: 0, y: 0 },
    elHeight: 1000,
    elWidth: "",
    categoriesExpanded: false,
  }),
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  el: "#keyword_view",
  methods: {
    numberFormatter,
  },
  components: {
    CardWithIcon,
    TrendChart,
    WaitAnimation,
    SerpTable,
    MaireCard,
    MaireTab,
  },
  mounted() {
    this.store.dispatch("keywords/getKeywordDetails", {
      kw_id: this.route.params.id,
      predictions: true,
      trends: true,
      historical_volume: true,
    });
  },
  computed: {
    keywordId() {
      return this.route.params.id;
    },
    keyword() {
      return this.store.state.keywords.keywordWithDetails;
    },
    userCompany() {
      return this.store.state.user.company?.id;
    },
    isAdmin() {
      return this.store.state.user.type === "admin";
    },
    company() {
      return this.store.state.company;
    },
    isLoading() {
      return this.store.state.loading.loadingKeywords;
    },
    sortedCategories() {
      const kw = this.store.state.keywords.keywordWithDetails;
      return recommendedCategories(kw);
    },
    typeAllocation() {
      const results = this.store.state.keywords.keywordWithDetails?.serp?.organic_results.filter(
        (result: any) => result.type !== "paid"
      );

      if (results) {
        return Object.fromEntries(
          Object.entries({
            Evaluatory: {
              size:
                results.filter((x: { category: string }) => x.category.split(" ")[0] === "Evaluatory")
                  .length * 10,
              title: "Evaluatory articles",
              img: "Evaluatory",
              description: "This content type is used when consumer is not yet fixated to a single solution.",
            },
            Actional: {
              size:
                results.filter((x: { category: string }) => x.category.split(" ")[0] === "Actional").length *
                10,
              title: "Actional articles",
              img: "Actional",
              description:
                "This content type is used when consumer is willing to implement an action. Actional page let's the consumer to do it.",
            },
            Illustrative: {
              size:
                results.filter((x: { category: string }) => x.category.split(" ")[0] === "Illustrative")
                  .length * 10,
              title: "Illustrative articles",
              img: "Illustrative",
              description:
                'This content type is used when consumer is looking for guidance. Guide content answers to a question "how to?"',
            },
            Explanatory: {
              size:
                results.filter((x: { category: string }) => x.category.split(" ")[0] === "Explanatory")
                  .length * 10,
              title: "Explanatory articles",
              img: "Explanatory",
              description:
                'This content type is used when consumer is looking for information. Explanatory content answers to a question "what is?"',
            },
            Reasoning: {
              size:
                results.filter((x: { category: string }) => x.category.split(" ")[0] === "Reasoning").length *
                10,
              title: "Reasoning articles",
              img: "Reasoning",
              description:
                "This content type is used when consumer wants to understand why some topic or function is important.",
            },
            Statistical: {
              size:
                results.filter((x: { category: string }) => x.category.split(" ")[0] === "Statistical")
                  .length * 10,
              title: "Statistical articles",
              img: "Statistical",
              description:
                "This content type is used when consumer is looking for numerical data or statistics related to a specific topic.",
            },
            "Product/Service": {
              size:
                results.filter((x: { category: string }) => x.category.split(" ")[0] === "Product/Service")
                  .length * 10,
              title: "Product/Service articles",
              img: "Product",
              description:
                "This content type is often used when the consumer already knows that it wants to buy something. Product/Service page describes the product/service that's been sold.",
            },
            Unknown: {
              size:
                results.filter((x: { category: string }) => x.category.split(" ")[0] === "Unknown").length *
                10,
              title: "Unknown type",
              img: "Unknown",
              description: "The type couldn't automatically be analyzed.",
            },
          })
            .sort((a: any, b: any) => b[1].size - a[1].size)
            .filter((x: any) => x[1].size > 0)
        );
      } else {
        return undefined;
      }
    },
  },
});
</script>
