import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01ac54f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label_cell inputs" }
const _hoisted_2 = ["onMouseenter", "onClick"]
const _hoisted_3 = {
  key: 0,
  style: {"display":"inline-block","margin-left":"12px"}
}
const _hoisted_4 = { class: "xs" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MaireTooltip = _resolveComponent("MaireTooltip")!
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.analyses.slice(0, _ctx.noOfVisible), (analysis) => {
      return (_openBlock(), _createElementBlock("div", {
        key: analysis.name,
        class: "category-input-field inline"
      }, [
        true
          ? (_openBlock(), _createBlock(_component_v_chip, {
              key: 0,
              style: _normalizeStyle({ 'background-color': 'rgb(var(--v-theme-mairePurpleSecondary)', color: 'white' }),
              class: "label"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MaireTooltip, null, {
                  trigger: _withCtx(() => [
                    _createElementVNode("span", {
                      class: "text-truncate label",
                      style: {"max-width":"50px","display":"block"},
                      onMouseenter: ($event: any) => (_ctx.tooltipVisible = analysis.id),
                      onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tooltipVisible = undefined)),
                      onClick: (e) => _ctx.handleAnalysisClick(e, analysis.id)
                    }, _toDisplayString(analysis.name), 41, _hoisted_2)
                  ]),
                  content: _withCtx(() => [
                    _createElementVNode("strong", null, _toDisplayString(analysis.name), 1),
                    _createElementVNode("p", null, _toDisplayString(analysis.description), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["style"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    (_ctx.analyses.length > _ctx.noOfVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_MaireTooltip, { follow: false }, {
            trigger: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, " + " + _toDisplayString(_ctx.analyses.length - _ctx.noOfVisible), 1)
            ]),
            content: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.analyses.slice(_ctx.noOfVisible, _ctx.analyses.length), (analysis) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: analysis.id,
                  onClick: (e) => _ctx.handleAnalysisClick(e, analysis.id),
                  class: "pointer label link"
                }, _toDisplayString(analysis.name), 9, _hoisted_5))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}