import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e68bd26"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "layer"
}
const _hoisted_2 = {
  key: 0,
  class: "dialogPopup inline"
}
const _hoisted_3 = { class: "white" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "content maire-scrollbar" }
const _hoisted_6 = {
  key: 1,
  class: "buttons"
}
const _hoisted_7 = {
  class: "btn",
  style: {"float":"right"}
}
const _hoisted_8 = {
  class: "btn cancel",
  style: {"float":"right"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MaireButton = _resolveComponent("MaireButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.open)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
              (_ctx.description)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.description), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              (!_ctx.hidebuttons)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_MaireButton, {
                        comfortable: "",
                        onClick: _ctx.fn,
                        text: _ctx.confirmText,
                        disabled: _ctx.confirmDisabled
                      }, null, 8, ["onClick", "text", "disabled"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_MaireButton, {
                        comfortable: "",
                        inverse: "",
                        onClick: _ctx.onClose,
                        text: _ctx.cancelText
                      }, null, 8, ["onClick", "text"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}