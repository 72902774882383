<template>
  <v-navigation-drawer
    v-if="user.email"
    railWidth="72"
    id="navbar"
    theme="drawerTheme"
    :rail="rail"
    permanent
  >
    <div
      :class="`drawer-open-button  ${rail ? 'nav-btn-open' : 'nav-btn-close'}`"
      @click.stop="handleNavRail"
    >
      <div style="height: 100%" icon>
        <v-icon style="height: 100%">{{ !rail ? "mdi:mdi-chevron-left" : "mdi:mdi-chevron-right" }}</v-icon>
      </div>
    </div>
    <div>
      <v-list>
        <SideBarItem
          :key="text"
          :link="link"
          :icon="icon"
          :text="text"
          :image="image"
          :items="items"
          :sublist="true"
          :activePath="activePath"
          :classList="classList"
          @toggle="toggle"
          v-for="{ link, icon, text, items, classList, image } in links"
        />
      </v-list>
    </div>
  </v-navigation-drawer>
</template>
<style scoped></style>

<script lang="ts" setup>
import { computed, ComputedRef, Ref, ref } from "vue";
import SideBarItem, { MenuItem } from "./SideBarItem.vue";
import { useStore } from "@/store";
const activePath: Ref<Set<string>> = ref(new Set());
const store = useStore();
const user = computed(() => store.state.user);
const setsEqual = (set: Set<any>, set2: Set<any>) =>
  set.size === set2.size && [...set].every((x) => set2.has(x));
const toggle = (sections: Set<string>) => {
  if (setsEqual(sections, activePath.value)) {
    const closingSection = Array.from(sections).pop();
    if (closingSection) {
      activePath.value.delete(closingSection);
    } else {
      activePath.value = new Set();
    }
    if (activePath.value.size === 0) rail.value = true;
  } else {
    activePath.value = sections;
    rail.value = false;
  }
};
const handleNavRail = () => {
  rail.value = !rail.value;
  if (rail.value) {
    activePath.value = new Set();
  }
};
const logout = () => {
  store.dispatch("logout");
};
const rail = ref(true);
const links: ComputedRef<MenuItem[]> = computed(() =>
  [
    {
      text: `${user.value.firstName} ${user.value.lastName}`,
      icon: user.value.impersonator ? "far fa-user-secret" : "far fa-user",
      image: user.value.image,
      items: [
        { text: "Personal information", icon: "far fa-user", link: "/me", hidden: true },
        { text: "Sign out", action: logout, icon: "fa-regular fa-sign-out" },
      ],
      classList: ["bottom-padding-20"],
    },
    {
      text: "Analyses",
      icon: "fa-regular fa-chart-pie",
      link: "/analysis",
      image: "",
      items: [...store.state.analysis.analyses]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((analysis) => ({
          text: analysis.name,
          items: [...analysis.views]
            .sort((a, b) => a.name.localeCompare(b.name))
            ?.map((view) => {
              return {
                text: view?.name,
                link: `/analysis/${analysis.id}?view=${view.id}`,
              };
            }),
        })),
    },
    {
      text: "Discovery",
      hidden: user.value.type === "viewer",
      icon: "far fa-key-skeleton",
      link: "/keywords",
    },
    {
      text: "Automation",
      hidden: !user.value.type?.includes("admin"),
      icon: "far fa-robot",
      items: [
        { text: "Label management", link: "/labels" },
        { text: "Saved discoveries", link: "/discoveries" },
      ],
    },
    {
      text: "Settings",
      hidden: !user.value.type?.includes("admin"),
      icon: "fa-regular fa-gear",
      link: "/settings",
    },
  ].filter((link) => !link.hidden)
);
</script>
